var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'user_admin_new'})}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-account-plus")]),_vm._v(" Add User ")],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("User "),_c('v-spacer'),(_vm.userList.length)?_c('v-btn',{staticClass:"mr-5",on:{"click":function($event){return _vm.downloadCSV(_vm.userList)}}},[_vm._v(" Download "),_c('v-icon',{staticClass:"ma-2"},[_vm._v("download")])],1):_vm._e()],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Search for User"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loadUserTable.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{staticClass:"mr-2",on:{"click":_vm.loadUserTable}},[_vm._v(" Search "),_c('v-icon',{staticClass:"ma-2"},[_vm._v("search")])],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.resetSearch}},[_vm._v(" Reset "),_c('v-icon',{staticClass:"ma-2"},[_vm._v("restore")])],1)]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.userList,"options":_vm.options,"server-items-length":_vm.totalUsers,"loading":_vm.loading,"footer-props":{
                  'items-per-page-options': _vm.rowsPerPage
                }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No Results Found. Please try removing filters or expanding your search. ")]},proxy:true},(_vm.employerStore.memberSettings.profileEdit)?{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(!item.isDeleted)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-2",on:{"click":function($event){return _vm.$router.push({ name: 'user_admin_edit', query: { userID: item.userID } })}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(!item.isDeleted)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-2",on:{"click":function($event){return _vm.deleteUser(item)}}},'v-icon',attrs,false),on),[_vm._v(" cancel ")])]}}],null,true)},[_c('span',[_vm._v("Deactivate")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-2",on:{"click":function($event){return _vm.restoreUser(item)}}},'v-icon',attrs,false),on),[_vm._v(" restore ")])]}}],null,true)},[_c('span',[_vm._v("Restore")])])]}}:null],null,true)})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }