<template>
  <div class="d-flex flex-column justify-space-between align-center mt-10">
    <v-img
      width="250px"
      :src="logoUrl"
    ></v-img>
  </div>
</template>

<script>
import { useEmployerStore } from "../stores/employer";

export default {
  name: "HeaderComponent",
  setup() {
    const employerStore = useEmployerStore();

    return {
      employerStore,
    };
  },
  data() {
    return {
      logoUrl: null,
    };
  },
  mounted() {
    if (this.employerStore.customizationSettings.mainLogo){
      this.logoUrl = this.employerStore.customizationSettings.mainLogo;
    } else {
      this.logoUrl = require("../assets/Revive_Black.png");
    }
  },

 
};
</script> 
