<template>
  <div>
    <h3 class="mb-5">Dashboard</h3>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card color="#ececec">
      <v-item-group>
        <v-container>
          <v-row class="mb-4">
            <!-- for the reports -->
            <v-col
              v-for="({ title, count }, index) in summaries"
              :key="index"
              md="2"
            > 
              <v-card height="19.25em">
                <v-card-title class="justify-center mb-16 blue--text">
                  {{ title }}
                </v-card-title>
                <div class="text-center">
                  <!-- <v-card-subtitle> -->
                    <span style="font-size:clamp(1.5rem, 10vw, 2.5rem); font-weight: bold;">{{ count }}</span>
                  <!-- </v-card-subtitle> -->
                </div>
              </v-card>
            </v-col>
            <!-- creates the chart -->

            <v-col md="4" height="19.25em">
              <v-card>
                <v-card-title class="justify-center blue--text">
                  {{employerStore.fieldLabelSettings.subscriberLabel}} Enrollments - Last 6 Months
                </v-card-title>
                <div class="d-flex justify-center align-center text-center">
                  <v-card-text>
                    <div class="chart" ref="memberEnrollment"></div>
                  </v-card-text>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="ma-1 bg-white" >
            <v-col md="12" >
              <div class="mapChart " ref="mapChart"></div>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
    </v-card>
  </div>
</template>

<script>
import states from "../../us_state_capital.json";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
// import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// am4core.useTheme(am4themes_dark);
am4core.useTheme(am4themes_animated);
import { useEmployerStore } from "../stores/employer";
import { useMemberStore } from "../stores/member";
import { useReportStore } from "../stores/report";
import { useAuthStore } from "../stores/auth";
import { gncRoles } from "../data/definitions/gncRoles";

export default {
  name: "DashboardComponent",
  setup() {
    const memberStore = useMemberStore();
    const employerStore = useEmployerStore();
    const reportStore = useReportStore();
    const authStore = useAuthStore();

    return {
      memberStore, employerStore, reportStore, authStore
    };
  },
  data() {
    return {
      overlay: false,
      totalPeople: 0,
      totalPrimaryMembers: 0,
      totalDependents: 0,
      itemsByMonth: null,
      itemsByRegion: null,
      newRegionData: [],
      totalActive: 0,
    };
  },
  computed: {
    summaries() {
      return [
       
        { title: this.employerStore.fieldLabelSettings.secondarySubscriberLabel, count: this.totalPrimaryMembers },
        { title: this.employerStore.fieldLabelSettings.secondaryDependentLabel, count: this.totalDependents },
        { title: "Total People", count: this.totalPeople },
        { title: "Active Households", count: this.totalActive },
      ];
    },
  },
  methods: {
    memberEnrollmentChart(data) {
      let chart = am4core.create(
        this.$refs.memberEnrollment,
        am4charts.XYChart
      );
      //Fetch data
      chart.data = data;

      //sets X axis
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "monthName";

      // sets Y axis
      chart.yAxes.push(new am4charts.ValueAxis());

      // sets series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.fill = am4core.color("#6894dd");
      series.dataFields.valueY = "value1";
      series.dataFields.categoryX = "monthName"; 

      chart.cursor = new am4charts.XYCursor();
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.menu.align = "right";
      chart.exporting.menu.verticalAlign = "top";

      chart.exporting.menu.items = [
        {
          label: "Export",
          menu: [
            {
              label: "Image",
              menu: [
                { type: "png", label: "PNG" },
                { type: "jpg", label: "JPG" },
                { type: "svg", label: "SVG" },
                { type: "pdf", label: "PDF" },
              ],
            },
            {
              label: "Print",
              type: "print",
            },
          ],
        },
      ];
    },
    createMap(data) {
      let newData = data;

      // Create map instance
      let chart = am4core.create(this.$refs.mapChart, am4maps.MapChart);
      chart.maxZoomLevel = 64;

      // Set map definition
      chart.geodata = am4geodata_usaLow;

      // Set projection
      chart.projection = new am4maps.projections.AlbersUsa();

      // Add button
      let zoomOut = chart.tooltipContainer.createChild(am4core.ZoomOutButton);
      zoomOut.align = "right";
      zoomOut.valign = "top";
      zoomOut.margin(20, 20, 20, 20);
      zoomOut.events.on("hit", function () {
        if (currentSeries) {
          currentSeries.hide();
        }
        chart.goHome();
        zoomOut.hide();
        currentSeries = regionalSeries.US.series;
        currentSeries.show();
      });
      zoomOut.hide();

      // Create map polygon series
      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.useGeodata = true;
      polygonSeries.calculateVisualCenter = true;

      // Configure series
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "{name}";
      polygonTemplate.fill = chart.colors.getIndex(1);

      // Load data when map polygons are ready
      chart.events.on("ready", loadStores);

      // Loads store data
      function loadStores() {
        setupStores(newData);
      }

      // Creates a series
      function createSeries(heatfield) {
        let series = chart.series.push(new am4maps.MapImageSeries());
        series.dataFields.value = heatfield;

        // let memberName = this.employerStore.fieldLabelSettings.subscriberLabel;
        let template = series.mapImages.template;
        template.verticalCenter = "middle";
        template.horizontalCenter = "middle";
        template.propertyFields.latitude = "lat";
        template.propertyFields.longitude = "long";
        const employerStore = useEmployerStore();

        template.tooltipText =
          `{name}:\n Total ${employerStore.fieldLabelSettings.subscriberLabel} Count: [bold]{count} [/]`;

        let circle = template.createChild(am4core.Circle);
        circle.radius = 10;
        circle.fillOpacity = 0.7;
        circle.verticalCenter = "middle";
        circle.horizontalCenter = "middle";
        circle.nonScaling = true;

        let label = template.createChild(am4core.Label);
        label.text = "{count}";
        label.fill = am4core.color("#fff");
        label.verticalCenter = "middle";
        label.horizontalCenter = "middle";
        label.nonScaling = true;

        series.heatRules.push({
          target: circle,
          property: "radius",
          min: 10,
          max: 30,
        });

        // Set up drill-down

        return series;
      }

      let regionalSeries = {};
      let currentSeries;

      function setupStores(data) {
        // Init country-level series
        regionalSeries.US = {
          markerData: [],
          series: createSeries("count"),
        };

        // Set current series
        currentSeries = regionalSeries.US.series;

        // Process data
        am4core.array.each(data, function (store) {
          // Get store data
          store = {
            state: store.state,
            long: am4core.type.toNumber(store.long),
            lat: am4core.type.toNumber(store.lat),
            count: am4core.type.toNumber(store.count),
          };

          // Process state-level data
          if (regionalSeries[store.state] == undefined) {
            let statePolygon = polygonSeries.getPolygonById(
              "US-" + store.state
            );
            if (statePolygon) {
              // Add state data
              regionalSeries[store.state] = {
                target: store.state,
                type: "state",
                name: statePolygon.dataItem.dataContext.name,
                count: store.count,
                lat: statePolygon.visualLatitude,
                long: statePolygon.visualLongitude,
                state: store.state,
                markerData: [],
              };
              regionalSeries.US.markerData.push(regionalSeries[store.state]);
            } else {
              // State not found
              return;
            }
          } else {
            regionalSeries[store.state].count += store.count;
          }
        });

        regionalSeries.US.series.data = regionalSeries.US.markerData;
      }
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.menu.align = "right";
      chart.exporting.menu.verticalAlign = "top";

      chart.exporting.menu.items = [
        {
          label: "Export",
          menu: [
            {
              label: "Image",
              menu: [
                { type: "png", label: "PNG" },
                { type: "jpg", label: "JPG" },
                { type: "svg", label: "SVG" },
                { type: "pdf", label: "PDF" },
              ],
            },
            {
              label: "Print",
              type: "print",
            },
          ],
        },
      ];
    },
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  mounted() {

    let roleName = localStorage.getItem("roleName");

    if(roleName == gncRoles.memberSupport){
      return this.$router.push({ name: "subscriber_admin_index" });
    }

    let currentEmployerID = localStorage.getItem("currentEmployerID");

    if(currentEmployerID){
      this.overlay = true;
  
      this.reportStore.getDashboardHouseHoldReport(currentEmployerID).then((result) => {
        this.totalActive = result.activeHouseholds;
        this.totalPeople = result.allMembers;
        this.totalDependents = result.dependentMembers;
        this.totalPrimaryMembers = result.primaryMembers;
  
          if(this.totalPeople == 0){
            this.$swal({
            title: "Info",
            text: "You currently have no "+ this.employerStore.fieldLabelSettings.secondarySubscriberLabel + ". Please add new " + this.employerStore.fieldLabelSettings.secondarySubscriberLabel + " to see this dashboard.",
            icon: "info",
            confirmButtonColor: this.$vuetify.theme.themes.light.success
          });
        }
      })
      .catch(() => {
        this.$swal({
          title: "Info",
          text: "You currently have no "+ this.employerStore.fieldLabelSettings.secondarySubscriberLabel + ". Please add new " + this.employerStore.fieldLabelSettings.secondarySubscriberLabel + " to see this dashboard.",
          icon: "info",
          confirmButtonColor: this.$vuetify.theme.themes.light.success
        });
      })
      .finally(() => this.overlay = false)
  
      
      this.reportStore.getDashboardChartReport(currentEmployerID).then((res) => {
        this.memberEnrollmentChart(res);
      });
  
      this.reportStore.getDashboardMapReport(currentEmployerID).then((result) => {
        let data = [];
  
        //get New Data for states
        states.forEach((state) => {
          const found = result.find((x) => x.primaryRegion == state.abbr);
          if (found) {
            let newObj = {
              state: found.primaryRegion,
              count: found.value1,
              lat: state.lat,
              long: state.long,
            };
            data.push(newObj);
          }
        });
  
        this.createMap(data);
      });
    }else{
    this.$swal({
      title: "Error",
      text: "An unknown error occured. Please contact support.",
      icon: "error",
      confirmButtonColor: this.$vuetify.theme.themes.light.success,
      showCancelButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false
      }).then(() => {
        this.$auth.logout({
          returnTo: window.location.origin
        });
      });
    }
  }
};
</script>

<style>

.bg-white {
  background-color: #ffffff;
}
.chart {
  width: 100%;
  height: 15em;
}
.mapChart {
  width: 100%;
  height: 30em;
}
.v-card__title {
  word-break: break-word !important;
}
.amcharts-amexport-item.amcharts-amexport-item-level-0 {
  opacity: 0.8 !important;
  width: 52px !important;
  min-height: 30px;
  transition: all 100ms ease-in-out 0s;
}
.fs-85 {
  font-size: 85px;
}
</style>
