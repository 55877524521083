<template>
    <div class="d-flex">
        <div v-for="item in list" :key="item.identifier" class="d-flex mr-6">
            <v-autocomplete
              v-model="selectedValues[item.identifier]"
              :label="item.name"
              :items="item.values"
              item-text="text"
              item-value="value"
              outlined
              dense
              chips
              small-chips
              multiple
							@change="emitSelectedFilters"
            ></v-autocomplete>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "DynamicFilter",
    props: {
      filterLists: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        selectedValues: {},
        list: [],
      };
    },
    methods: {
      emitSelectedFilters() {
				if (this.filterSelectEventEmitter)
					clearTimeout(this.filterSelectEventEmitter);
				this.filterSelectEventEmitter = setTimeout(() => {
					const selectedFilters = {};
					for (const key in this.selectedValues) {
						if (this.selectedValues[key].length > 0) {
							selectedFilters[key] = this.selectedValues[key];
						}
					}
					this.$emit("filters-applied", selectedFilters);
					this.filterSelectEventEmitter = null;
				}, 1500);
      },
      beforeEnter(el) {
        el.style.opacity = 0;
        el.style.width = '0px';
        el.style.height = '0px';
        el.style.overflow = 'hidden'; // Ensure overflow is hidden during transition
			},
			enter(el, done) {
        // Set the width and height to auto to get their natural dimensions
        el.style.width = 'auto';
        el.style.height = 'auto';
        const targetWidth = `${el.scrollWidth}px`;
        const targetHeight = `${el.scrollHeight}px`;

        // Reset width and height to 0 before the transition
        el.style.width = '0px';
        el.style.height = '0px';
        
        // Trigger reflow
        el.offsetHeight;

        // Apply the final dimensions
        el.style.transition = 'opacity 0.5s ease-in-out, width 0.5s ease-in-out, height 0.5s ease-in-out';
        el.style.opacity = 1;
        el.style.width = targetWidth;
        el.style.height = targetHeight;

        // Ensure that the entire content is visible
        el.style.overflow = 'visible';
        
        done();
			},
			leave(el, done) {
				const currentWidth = `${el.scrollWidth}px`;
				const currentHeight = `${el.scrollHeight}px`;
				
				el.style.width = currentWidth;
				el.style.height = currentHeight;

				el.offsetHeight;

				el.style.transition = 'opacity 0.5s ease-in-out, width 0.5s ease-in-out, height 0.5s ease-in-out';
				el.style.opacity = 0;
				el.style.width = '0px';
				el.style.height = '0px';

				done();
			},
			afterLeave(el) {
				el.style.overflow = 'hidden';
			}
    },
    watch: {
      filterLists: {
        handler(newLists) {
          this.list = [];
          newLists.forEach((item) => {
            if(item.type != 'text-field'){
              this.list.push(item);
            }
            this.$set(this.selectedValues, item.identifier, []);
          });
        },
        immediate: true,
      },
    },
  };
  </script>
  
  <style scoped>
.element {
  overflow: hidden;
}
  </style>
  