import apiClient from "../services/api";
import { defineStore } from "pinia";

export const useLookupStore = defineStore("lookup", {
  state: () => ({
      genderList: [],
      memberType: [],
      employerType: [],
      messageType: []
  }),
  getters: {

  },
  actions: {
    async getGenderList() {
      try {
        await apiClient.get("/lookup/getLookupListByType?type=Gender").then((res) => {
          this.genderList = res.data;
        })
      }
      catch {
        this.genderList = [];
      }
    },
    async getMemberTypeList() {
      try {
        await apiClient.get("/lookup/getLookupListByType?type=MemberType").then((res) => {
          this.memberType = res.data;
        })
      }
      catch {
        this.memberType = [];
      }
    },
    async getEmployerTypeList() {
      try {
        await apiClient.get("/lookup/getLookupListByType?type=Employer").then((res) => {
          this.employerType = res.data;
        })
      }
      catch {
        this.employerType = [];
      }
    },
    async getMessageTypeList() {
      try {
        await apiClient.get("/lookup/getLookupListByType?type=MemberMessaging").then((res) => {
          this.messageType = res.data;
        })
      }
      catch {
        this.messageType = [];
      }
    },
  },
});
