<template>
    <v-container>
        <p class="text-decoration-underline font-weight-bold text-center">SERVICES AGREEMENT</p>
        <span>
            This <strong>Agreement</strong> (the “Agreement”) is made as of as of the date of Plan Sponsor’s electronic acceptance of these terms, (“Effective Date”) between ReviveHealth, Inc with principal offices located at 5000 Sawgrass Village Circle, Suite 4, Ponte Vedra Beach, FL 32082, its portfolio of companies, and its affiliated medical practices (collectively, <strong>“Revive”</strong>) and (<strong>“Program Sponsor”</strong>). Revive and Program Sponsor are collectively referred to herein individually as <strong>“Party”</strong> and collectively as the <strong>“Parties.”</strong>
        </span>

        <p class="text-decoration-underline font-weight-bold text-center mt-5">REVIVE TERMS AND CONDITIONS</p>
        
        <ol type="1"> 
            <li>
                <strong>DEFINITIONS.</strong> The following definitions will apply to capitalized terms used throughout these Terms and Conditions.
                <ol type="a">
                    <li class="mt-3"><strong class="font-italic">“Customer Support”</strong> means the customer support line operated by Revive to assist customers with troubleshooting and questions regarding use of the Membership Platform.</li>
                    <li><strong class="font-italic">“Intellectual Property Rights”</strong> means any patent, invention, discovery, know-how, moral, technology, software, copyright, authorship, trade secret, trademark, trade dress, service mark, confidentiality, proprietary, privacy, intellectual property, or similar rights (including rights in RPM applications, registrations, filings, and renewals) that are now or hereafter protected or legally enforceable under state and/or Federal common laws or statutory laws or laws of foreign jurisdictions.</li>
                    <li><strong class="font-italic">“Member” or “Members”</strong> means the employees and subcontractors of Program Sponsor that choose to enroll in the Services and that Program Sponsor purchases the Services for.</li>
                    <li><strong class="font-italic">“Membership Platform”</strong> means the software platform (myrevive.health) provided to Members and all associated capabilities and functionalities, through which Members can access the Services.</li>
                    <li><strong class="font-italic">“Revive Data”</strong>means: (a) all data, software (in any form) and information Revive submits or transmits to Program Sponsor regarding Revive; (b) all data, records and information generated in Revive’s business or operations, including any information relating to Revive’s subcontractors and/or affiliates; (c) all Revive Intellectual Property (defined below), together with all derivative works of the Revive Intellectual Property; and (d) data, records or information occurring in any form, including written, graphic, electronic, visual or fixed in any tangible medium of expression and whether developed, generated, stored, possessed or used by Revive, . Program Sponsor, or a third party if related to the items described in (a) through (c) above. Revive Data does not include any data or information that relates exclusively to Program Sponsor or Program Sponsor ’s business, operations, or activities.</li>
                    <li><strong class="font-italic">“Services”</strong> as indicated on the Revive company website at the time of Program Sponsor’s acceptance of these terms.</li>
                    <li><strong class="font-italic">“Terms of Use”</strong> means the agreement between each individual user of the Membership Platform and Revive governing a Member’s use of the Membership Platform.</li>
                </ol>
            </li>
            <li class="mt-3">
                <strong>TERM.</strong> The term of this contract is for 12 months with an automatic renewal, unless cancelled by the Program Sponsor.
            </li>
            <li class="mt-3">
                <strong>MEMBERSHIP PLATFORM LICENSE.</strong> Revive will grant to each Member a limited, revocable, non-exclusive, non-transferable license to access and use the Membership Platform for purposes of accessing the Membership Platform. Members may use the Membership Platform to access and use the Membership Platform only in accordance with the relevant Terms of Use and any other supporting documentation provided by Revive.
            </li>
            <li class="mt-3">
                <strong>Program Sponsor RESPONSIBILITIES.</strong>
                <ol type="a">
                    <li class="mt-3"><u>Operations & Enrollment.</u> Program Sponsor is responsible for providing all reasonably requested Member information to Revive to allow Revive to furnish the Services to Members. Program Sponsor is responsible for the accuracy, quality, integrity, legality, reliability, and appropriateness of all information provided to Revive in order for Members to enroll in the Services. </li>
                    <li class="mt-3"><u>System.</u> With the exception of the Membership Platform, Employer Portal, and Services provided under these Terms and Conditions, Members will be responsible for (a) obtaining, deploying and maintaining all hardware, software, and equipment necessary to access and use the Services; (b) contracting with third party ISP, telecommunications and other service providers as necessary to access and use the Services; and (c) paying all third-party fees and access charges incurred in connection with the foregoing. Except as specifically set forth in these Terms and Conditions, ReviveHealth will not be responsible for supplying any hardware, software, or other equipment to Program Sponsor or Members under these Terms and Conditions and cannot be held responsible for any interruption in the Services that occurs as a result of any of the foregoing or Program Sponsor ‘s or a Member’s failure to maintain any of the foregoing.</li>
                    <li class="mt-3"><u>TOU.</u> Program Sponsor and Members are responsible for Members’ actions while using the Membership Platform. As a condition to each Member’s use of the Membership Platform and access to the Services, Members will be required to review and accept the Revive Terms of Use <strong class="font-italic">(“TOU”)</strong>, as updated by Revive from time to time, prior to accessing the Membership Platform. Program Sponsor shall ensure that its Members abide by the TOU when using or accessing the Membership Platform.</li>
                </ol>
            </li>
            <li class="mt-3">
                <strong>PAYMENT.</strong>
                <ol type="a">
                    <li class="mt-3"><u>Fees.</u> As compensation for the Services, Program Sponsor will pay Revive all Fees as indicated on the Revive company website at the time of Program Sponsor’s acceptance of these terms or as indicated by Revive’s reseller partner.  All amounts are denominated and shall be paid in U.S (United States) dollars.</li>
                    <li class="mt-3"><u>Invoicing & Payment Method.</u> Revive will draft fees owed to Revive on a monthly basis via Program Sponsor’s designated bank account or charge Program Sponsor’s credit card as directed by Program Sponsor according to the terms set forth in these Terms and Conditions. Program Sponsor can update or change their payment method via the Revive Sponsor Portal.</li>
                    <li class="mt-3">
                        <span><u>Payment Cycles.</u> Program Sponsor is responsible for transmitting to Revive an accurate roster of Members to be enrolled in the Services by the 25th of each month via the Revive Sponsor portal. All new Members included on this roster will be enrolled in the Services as of the first (1st) of the following month. Revive will electronically send an invoice by the first (1st) day of each month for all Members enrolled in the Services for that month and any members added and effective in the previous month that were not included on the previous invoice. Revive will also debit the Program Sponsors bank account or credit card for the amount of the invoice on or around the first (1st) day of each month. In the event that this process fails, the Program Sponsor must pay all invoiced amounts to Revive within thirty (30) days of Program Sponsor’s receipt of an invoice.</span>
                        <ol type="i">
                            <li class="mt-3"><u>Disputed Payments.</u> If Program Sponsor wishes to dispute any invoiced fees or expenses, Program Sponsor must notify Revive in writing within ten (10) business days of receipt of the invoice specifying such fees or expenses (a “<strong class="font-italic">Dispute Notice</strong>”). The Dispute Notice must specify the amounts that are being disputed as well as the reason for such dispute. Revive and Program Sponsor agree to attempt to resolve such dispute through informal meetings and discussions in good faith between appropriate representatives of the Parties within forty-five (45) days of receipt of the Dispute Notice before resorting to any other dispute resolution procedure.</li>
                            <li class="mt-3"><u>Overdue Payments.</u> Any undisputed amount not timely paid to Revive (an <strong class="font-italic">“Overdue Payment”</strong>) may accrue, at Revive’s discretion, late charges at the rate of the lesser of two percent (2%) of the outstanding balance per month, or the maximum rate permitted by law, from the date such payment was due until the date paid. If there are undisputed payments outstanding for more than thirty (30) days from the due date, ReviveHealth reserves the right to suspend Authorized Users’ access to the Membership Platform until such amounts are paid in full. Program Sponsor will continue to be obligated to pay all Fees during any such suspension period.</li>
                        </ol>
                    </li>
                    <li class="mt-3"><u>Taxes.</u> All amounts payable to Revive pursuant to these Terms and Conditions are exclusive of all local, state, federal and foreign taxes, levies, or duties of any nature (“Taxes”), and all payments to Revive are payable in full without reduction for Taxes. Program Sponsor is responsible for payment of all Taxes, excluding taxes owed by Revive based on Revive’s net income. If ReviveHealth is legally obligated to pay or collect Taxes for which Program Sponsor is responsible, the appropriate amount will be invoiced to and paid by Program Sponsor unless Program Sponsor provides Revive with a valid tax exemption certificate authorized by the appropriate taxing authority.</li>
                </ol>
            </li>
            <li class="mt-3">
                <strong>PROPRIETARY RIGHTS.</strong>
                <ol type="a">
                    <li class="mt-3">
                        <strong>Intellectual Property.</strong> As between Revive and Program Sponsor, all right, title, and interest, including all Intellectual Property Rights, in the Membership Platform, Revive Data, and any other Revive property or materials furnished or made available as part of the Services, and all modifications and enhancements of the same (“<strong class="font-italic">Revive Intellectual Property</strong>”), belong to and are retained solely by Revive or Revive’s licensors and providers, as applicable. Nothing in these Terms and Conditions is intended to or may be construed to transfer any such rights in any part of the Services to Program Sponsor other than as explicitly provided for in these Terms and Conditions. Program Sponsor shall not re-distribute the Membership Platform or the Devices other than as specifically provided for in these Terms and Conditions.
                        <ol type="i">
                            <li class="mt-3"><u>Developments.</u> Except as otherwise explicitly set forth in these Terms and Conditions, all inventions, works of authorship, and developments conceived, created, written, or generated by or on behalf of Revive, whether solely or jointly, in connection with the Services (“<strong class="font-italic">Revive Developments</strong>”) and all Intellectual Property Rights in the same, shall be the sole and exclusive property of Revive. Program Sponsor agrees to execute any documents or take any actions as may reasonably be necessary, or as Revive may reasonably request, to perfect Revive’s ownership of the Revive Developments. </li>
                        </ol>
                    </li>
                    <li class="mt-3">
                        <u>Trademarks.</u>  Nothing in these Terms and Conditions shall grant either Party ownership interest, license or other right to the other Party’s trade names, trademarks, or service marks, except as expressly provided in these Terms and Conditions. 
                    </li>
                    <li class="mt-3">
                        <u>Feedback License.</u> Revive owns all right, title, and interest in and to any suggestion, enhancement, request, recommendation, or other feedback related to the Membership Platform or Services provided by Program Sponsor or a Member (any “Feedback”). Feedback is not Program Sponsor’s Confidential Information.
                    </li>
                </ol>
            </li>
            <li class="mt-3">
                <strong>CONFIDENTIALITY.</strong>
                <ol type="a">
                    <li class="mt-3">
                        <u>Confidential Information Defined.</u> 
                        <strong class="font-italic">“Confidential Information”</strong> means any and all non-public technical and non-technical information disclosed by one Party (the “<strong class="font-italic">Party</strong>”) to the other Party (the “<strong class="font-italic">Receiving Party</strong>”) in any form or medium, that the Disclosing Party identifies as confidential or that by the nature of the circumstances surrounding the disclosure and/or receipt ought to be treated as confidential and proprietary information. Confidential Information includes, without limitation, (a) techniques, inventions (whether or not patented or patentable), know-how, processes, algorithms, software programs, software source and object codes and documents, APIs, and other creative works (whether or not copyrighted or copyrightable); (b) financial information, customer lists, business forecasts, and marketing programs and information; (c) the business relationships and affairs of either party and its clients, patients, and referral sources; (d) the internal policies and procedures of either Party; (e) proprietary or confidential information of any third party who may disclose such information to Disclosing Party or Receiving Party in the course of Disclosing Party’s business; and (f) the terms of these Terms and Conditions. Revive’s Confidential Information includes the Membership Platform and Revive Data. Confidential Information also includes all summaries and abstracts of Confidential Information. 
                        <ol type="i">
                            <li class="mt-3"><u>Exceptions.</u> The term “Confidential Information” shall not include any information which, as evidenced by Receiving Party’s records:  (i) was known by the Receiving Party prior to receipt from the Disclosing Party either itself or through receipt directly or indirectly from a source with no obligation of confidentiality to the Disclosing Party; (ii) was developed by the Receiving Party without use of the Disclosing Party’s Confidential Information, or (iii) becomes publicly known or otherwise ceases to be secret or confidential, except as a result of a breach of these Terms and Conditions or any obligation of confidentiality by the Receiving Party.</li>
                        </ol>
                    </li>
                    <li class="mt-3"><u>Confidential Information Terms.</u> The Receiving Party will, at all times, both during the term and thereafter, keep in confidence and trust all the Disclosing Party’s Confidential Information. The Receiving Party will not use the Disclosing Party’s Confidential Information other than as necessary to fulfill the Receiving Party’s obligations or to exercise the Receiving Party’s rights under these Terms and Conditions. Either Party may disclose the other Party’s Confidential Information upon the order of any competent court or government agency; provided that, prior to disclosure and to the extent possible, the receiving Party must (i) assert the confidential nature of the Confidential Information to the agency; (ii) immediately notify the Disclosing Party in writing of the order or request; and (iii) cooperate fully with the Disclosing Party in protecting against any such disclosure and/or narrowing the scope of the compelled disclosure. Each Party agrees to secure and protect the other Party’s Confidential Information with the same degree of care and in a manner consistent with the maintenance of such Party’s own Confidential Information (but in no event less than reasonable care). The Receiving Party will not disclose Confidential Information of the Disclosing Party to any person or entity other than its officers, employees, affiliates, and agents who need access to such Confidential Information in order to effect the intent of these Terms and Conditions and who are subject to confidentiality obligations at least as stringent as the obligations set forth in these Terms and Conditions.</li>
                    <li class="mt-3"><u>Injunctive Relief.</u> The Parties agree that any unauthorized disclosure of Confidential Information may cause immediate and irreparable injury to the Disclosing Party and that, in the event of such breach, the Receiving Party will be entitled, in addition to any other available remedies, to seek immediate injunctive and other equitable relief, without bond and without the necessity of showing actual monetary damage.</li>
                </ol>
            </li>
            <li class="mt-3">
                <strong>TERMINATION.</strong>
                <ol type="a">
                    <li class="mt-3"><u>Without Cause.</u> Either Party may terminate these Terms and Conditions without cause upon thirty (30) days’ written notice to the other Party.</li>
                    <li class="mt-3"><u>Mutual Agreement.</u> These Terms and Conditions shall terminate upon the mutual written agreement of Program Sponsor and Revive as of the date of signature or other effective date set forth on the written instrument.</li>
                    <li class="mt-3">
                        <u>For Cause.</u>
                        <ol type="i">
                            <li class="mt-3"><u>Material Breach.</u> Either Party may terminate these Terms and Conditions following a material breach of these Terms and Conditions by the other Party which is not cured during the Cure Period. The non-breaching Party shall notify the breaching Party of the breach in writing and the breaching party shall have thirty (30) days (the “Cure Period”) to cure the breach following receipt of the notification. If the breaching Party fails to cure the breach within the Cure Period, then the non-breaching Party may terminate these Terms and Conditions upon written notice to the breaching party. If Program Sponsor breaches, the parties agree that the Program Sponsor will pay all undisputed fees for all services provided during the cure period as set forth in Section 5.</li>
                            <li>
                                <u>Other Cause.</u> Revive may terminate these Terms and Conditions immediately by providing written notice to Program Sponsor upon the occurrence of any of the following events:
                                <ol type="1">
                                    <li>Revive reasonably determines that Program Sponsor and/or its Authorized User(s) have been or are engaged in unlawful activity associated with the use of the Membership Platform and/or the Services;</li>
                                    <li>The indictment or conviction of Program Sponsor or its principals, employees, or agents for any felony or misdemeanor involving moral turpitude;</li>
                                    <li>The filing, with respect to Program Sponsor, of a voluntary or involuntary petition in bankruptcy if such petition is not dismissed within thirty (30) days of such filing;</li>
                                    <li>Upon the appointment of a receiver or trustee to take possession of all, or substantially all, of Program Sponsor’s assets, if such appointment is not terminated within thirty (30) days; and/or</li>
                                    <li>For any other reason Revive feels could reasonably jeopardize the integrity or reputation of its operations or systems.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li class="mt-3"><u>Effect of Termination.</u> Unless otherwise stated below, upon expiration or termination of these Terms and Conditions for any reason, (a) the License shall terminate and Members shall not use or access, directly or indirectly, the Membership Platform; (b) Revive’s obligation to perform the Services shall cease; and (c) all fees and other amounts owed to Revive that accrued prior to expiration or termination will be immediately due and payable. Program Sponsor will be responsible for notifying Members of such termination no later than twenty-one (21) days prior to the effective date of such expiration or termination. Further, if Program Sponsor has made any copies of any Revive property or materials furnished or made available under these Terms and Conditions, Program Sponsor shall, within thirty (30) days of the effective date of the expiration or termination, either destroy or return to Revive all such copies along with a certificate signed by Program Sponsor that all such copies have been either destroyed or returned, respectively, and that no copy or any part of the Membership Platform, data, or other materials has been retained by Program Sponsor in any form.</li>
                </ol>
            </li>
            <li class="mt-3">
                <strong>REPRESENTATIONS & WARRANTIES.</strong>
                <ol type="a">
                    <li class="mt-3"><u>Mutual Representations and Warranties.</u> Each Party represents, warrants and covenants that: (a) to its knowledge, it has the full power and authority to enter into these Terms and Conditions and to perform its obligations hereunder, without the need for any consents, approvals or immunities not yet obtained; (b) its acceptance of and performance under these Terms and Conditions will not breach any oral or written agreement with any third party or any obligation it owes to any third party; and (c) it will comply with any and all applicable local, state, and/or national laws or regulations applicable to such party, including, without limitation, those related to PHI (Protected Health Information), Covered Entities, and Business Associates as each term is defined under HIPAA, and to any other laws or regulations regarding data privacy and transmission of personal data.</li>
                    <li class="mt-3"><u>Practice of Medicine.</u> Program sponsor hereby agrees and acknowledges that revive is a medical provider for members only and not for program sponsor as an entity. As such, revive cannot be held responsible to the company for any substandard treatment or medical malpractice claims with respect to any services delivered to members under these terms and conditions.</li>
                    <li class="mt-3"><u>Disclaimer.</u> Except for the express warranties set forth in this section 9, <strong>the services are provided on an as-is basis. Program sponsor’s use of the software and purchase of the services are at its own risk. Revive does not make, and hereby disclaims, any and all other express, statutory, and implied warranties, including without limitation, warranties of merchantability, fitness for a particular purpose, noninfringement and accuracy (of data or any other information or content), and any warranties arising from a course of dealing, usage, or trade practice.</strong> Any warranties made by revive are for the benefit of program sponsor only and not for the benefit of any third party. The software may be subject to limitations, delays, and other problems inherent in the use of the internet and electronic communications, such as delays caused by third-party network providers, that are outside Revive’s control. Revive is not responsible for any delays, delivery failures, or other damages resulting from such problems. The entire risk arising out of use or performance of the software, including without limitation any information, data, products, processes, and other matters referenced by the services remains with the program sponsor and each member. Except as expressly provided herein, revive does not guarantee continuous, error-free, virus-free, or secure operation and access to the software. </li>
                    <li class="mt-3"><u>Basis of the Bargain.</u> Program sponsor acknowledges and agrees that revive has offered its services and entered into these terms and conditions to which it is a party in reliance upon the warranty disclaimers and the limitations of liability set forth herein, that the warranty disclaimers and the limitations of liability set forth herein reflect a reasonable and fair allocation of risk between program sponsor and revive, and that the warranty disclaimers and the limitations of liability set forth herein form an essential basis of the bargain between program sponsor and revive. Program sponsor acknowledges and agrees that revive would not be able to provide the services to program sponsor on an economically reasonable basis without these limitations. </li>
                </ol>
            </li>
            <li class="mt-3">
                <strong>INSURANCE AND INDEMNIFICATION.</strong>
                <ol type="a">
                    <li class="mt-3"><u>Indemnification by Program Sponsor.</u> Program Sponsor shall indemnify and hold harmless Revive and its officers, directors, employees and agents (“<strong class="font-italic">Revive Indemnified Parties</strong>”), from and against any and all damages, liabilities, penalties, interest, fines, losses, costs and expenses (including reasonable attorneys’ fees and expenses) (“<strong class="font-italic">Losses</strong>”), arising, directly or indirectly, out of or relating to any claim, action or proceeding (a “<strong class="font-italic">Claim</strong>”) brought by a third party based on (i) the improper use or operation of the Services (and any third-party software provided to Program Sponsor pursuant to these Terms and Conditions) by Members or Program Sponsor; (ii) a breach of the Agreement and/or the TOU by Program Sponsor or any Member(s); (iii) violation of any applicable law, rule or regulation by Program Sponsor or any Member with respect to the Services; and/or (iv) the negligent acts or willful misconduct of a Member, Program Sponsor or its personnel.  Program Sponsor will pay all Losses (whether by settlement or award of by a final judicial judgment) incurred by the Revive Indemnified Parties from any such Claim.</li>
                </ol>
            </li>
            <li class="mt-3">
                <strong>LIMITATIONS OF LIABILITY.</strong>
                <ol type="a">
                    <li class="mt-3"><u>No Consequential Damages.</u> Neither party will be liable for any indirect, incidental, special, consequential or punitive damages, or any damages for lost data, business interruption, lost profits, lost revenue or lost business arising out of or in connection with these terms and conditions, including without limitation any such damages arising out of Revive’s provision or program sponsor’s use of the software or the results thereof, even if a party has been advised of the possibility of such damages. In no event will revive be liable for the cost of procurement of substitute goods or services.</li>
                    <li class="mt-3"><u>Limits on Liability.</u> In no case will either party be liable for any aggregate amount greater than the amounts paid and payable by program sponsor to revive under these terms and conditions during the twelve (12) month period preceding the date on which the claim first accrued, without regard to whether such claim is based in contract, tort (including negligence), product liability or otherwise.</li>
                    <li class="mt-3"><u>Essential Purpose.</u> Program sponsor acknowledges that the terms in this section 12 (limitation of liability) are a bargained for reasonable allocation of the risk between the parties and will apply (a) to the maximum extent permitted by applicable law, and (b) even if an exclusive or limited remedy stated herein fails of its essential purpose.</li>
                    <li class="mt-3"><u>Limitation of Action.</u> No action (regardless of form) arising out of these Terms and Conditions may be commenced by Program Sponsor against Revive more than two (2) years after the cause of action arose.</li>
                </ol>
            </li>
            <li class="mt-3">
                <strong>MISCELLANEOUS.</strong>
                <ol type="a">
                    <li class="mt-3"><u>Subcontractors.</u> Revive may use its affiliates or subcontractors to perform its obligations under these Terms and Conditions. </li>
                    <li class="mt-3"><u>Notices.</u> Any notices, requests, consents, demands or other communications required or permitted under these Terms and Conditions will be in writing and deemed to have been duly given either: (a) when delivered, if delivered by hand, sent by United States registered or certified mail (return receipt requested), delivered personally by commercial courier, or sent by email; or (b) on the second following business day, if sent by United States Express Mail or a nationally recognized commercial overnight courier; and in each case to the parties at the following addresses (or at other addresses as specified by a notice) with applicable postage or delivery charges prepaid. Notices to Revive shall be sent to the following address: ReviveHealth, Inc, 5000 Sawgrass Village Circle, Suite 4, Ponte Vedra Beach, FL 32082. Notices to Program Sponsor shall be sent to the address listed on the Purchase Order.</li>
                    <li class="mt-3"><u>Amendment.</u> These Terms and Conditions may be updated by Revive at any time, provided, however, that Revive will provide notice to Program Sponsor of any such changes and allow Program Sponsor to revoke acceptance of such changes where required by applicable law.</li>
                    <li class="mt-3"><u>Waiver; Severability.</u> A Party’s right to enforce a provision of these Terms and Conditions may only be waived in writing and signed by the Party against which the waiver is to be enforced. Failure to enforce any provision of these Terms and Conditions in any one instance will not be construed as a waiver of future performance of that provision, and the Party’s obligations under that provision will continue in full force and effect. The provisions of these Terms and Conditions are severable. The invalidity or unenforceability of any term or provision in any jurisdiction will be construed and enforced as if it has been narrowly drawn so as not to be invalid, illegal, or unenforceable to the extent possible and will in no way affect the validity or enforceability of any other terms or provisions in that jurisdiction or of this entire Agreement in that jurisdiction.</li>
                    <li class="mt-3"><u>Governing Law & Venue.</u> These Terms and Conditions, any additional applicable Terms & Conditions, and each Party’s rights and obligations under each will be governed by and construed in accordance with the laws of the State of North Carolina without giving effect to conflicts of law principles. Each Party hereby submits to the jurisdiction of any court in the state of North Carolina.</li>
                    <li class="mt-3"><u>Assignment.</u> Neither Party may assign or transfer these Terms and Conditions or any additional applicable Terms & Conditions without the prior written consent of the other Party; provided, however, that Revive may assign or transfer these Terms and Conditions without Program Sponsor’s consent to any of Revive’s affiliates, subsidiaries, entities controlled by or under common control with Revive, or in the event of a merger, change of control or sale of substantially all of its assets. These Terms and Conditions will bind the Parties and their respective successors and permitted assigns and will inure to the benefit of the Parties and their respective permitted successors and assigns.</li>
                    <li class="mt-3"><u>Force Majeure.</u> If any Party is unable to perform any of its obligations under these Terms and Conditions (with the exception of payment obligations) because of any cause beyond the reasonable control of and not the fault of the Party invoking this section, including any act of God, fire, casualty, flood, earthquake, war, strike, lockout, epidemic or pandemic, destruction of production facilities, riot, insurrection or material unavailability, and if the non-performing Party has been unable to avoid or overcome its effects through the exercise of commercially reasonable efforts, such non-performing Party will give prompt notice to the other Party, its performance will be excused, and the time for its performance will be extended for the period of delay or inability to perform due to such occurrences.  If performance is extended under this section for more than sixty (60) days, then at any time before reinstatement of the performance, the other Party may terminate these Terms and Conditions upon notice to the non-performing Party.</li>
                    <li class="mt-3"><u>Relationship of the Parties.</u> The sole relationship between the Parties is solely that of independent contractors. These Terms and Conditions will not create a joint venture, partnership, agency, employment, or other relationship between the Parties. </li>
                    <li class="mt-3"><u>Survival.</u> Any term of these Terms and Conditions that contemplates performance after termination of these Terms and Conditions will survive expiration or termination and continue until fully satisfied.</li>
                    <li class="mt-3"><u>Dispute Resolution.</u> In case of disputes in connection with the negotiation, execution, interpretation, performance or non-performance of these Terms and Conditions, the Parties agree to seek non-binding mediation, which shall be conducted remotely by a single mediator selected by the Parties. If the Parties fail to agree on the mediator within thirty (30) days of the date one of them invokes this provision, either Party may apply to the American Arbitration Association to make the appointment. The mediator shall conduct the proceedings pursuant to the rules of the American Arbitration Association, as they exist at the time of the dispute. In the event that any such mediation does not produce a settlement, unless the dispute is otherwise settled, the dispute shall be determined by binding and final arbitration in Florida, by three (3) arbitrators selected by the Parties (or by the American Arbitration Association if the Parties cannot agree) in accordance with the law of the State of Florida and the rules of the American Arbitration Association. </li>
                    <li class="mt-3"><u>Entire Agreement.</u> These Terms and Conditions, including all applicable Attachments, constitutes the entire agreement between the Parties relating to this subject matter and supersedes all prior or simultaneous understandings, representations, discussions, negotiations, and agreements, whether written or oral. </li>
                    <li class="mt-3"><u>Counterparts.</u> These Terms and Conditions may be executed in one or more counterparts. Each counterpart will be an original, but all such counterparts will constitute a single instrument.</li>
                </ol>
            </li>
        </ol>

        <div class="mt-5">
            <p class="font-weight-bold text-center">SERVICE TERM</p>
            <strong>One (1) year Service Term that automatically renews on the anniversary of inception for an additional twelve (12) month Service Term. If the Customer chooses to opt out of the Services provided at any time, the Customer shall provide thirty (30) days’ notice in writing to <a href="mailto:info@revive.health">info@revive.health</a></strong>
        </div>

        <div class="mt-5">
            <p class="text-decoration-underline font-weight-bold text-center">ACKNOWLEDGMENT</p>
            <span>This Agreement and the Services are subject to the Revive Terms of Service above. By signing this Agreement below, you acknowledge that you have read, understand, and agree to purchase the Services indicated in Appendix A below according to the applicable Terms and Conditions, including without limitation all warranty disclaimers, liability limitations, and use limitations. You also acknowledge and understand that doing so forms a legally binding contract between your organization and ReviveHealth, Inc, and its affiliated medical practices (the “Revive Network”), with principal offices at 5000 Sawgrass Village Circle, Suite 4, Ponte Vedra Beach, FL 32082 ("Revive") and you represent that you are legally authorized to enter into a legally binding contract on behalf of your organization. </span>
        </div>
    </v-container>
</template>

<script>
export default {

}
</script>
