import { DateTime, Info } from "luxon";
import { lookupTypes } from "../data/definitions/lookupTypes";

export const ftpDateFormats = [
  {valueType: "M/d/yyyy", name: "M/D/YYYY"},
  {valueType: "yyyyMMdd", name: "YYYYMMDD"},
  {valueType: "MMddyyyy", name: "MMDDYYYY"}
];

export const cleanDate = (date) => {
  return DateTime.fromISO(date).toFormat("MM/dd/yyyy");
};

export const calendarDate = (date) => {
  return DateTime.fromISO(date).toFormat("yyyy-MM-dd");
};

export const addOneYearToDate = (date) => {
  date.setFullYear(date.getFullYear() + 1);
  return date.toISOString().substr(0, 10);
};
export const minCalendarDate = () => {
  return new Date().toISOString().substring(0, 10);
};
export const checkValidity = (stringDate) => {
  let date = DateTime.fromFormat(stringDate, "d-M-y");
  if (date.invalid === null) {
    return true;
  }
  date = DateTime.fromFormat(stringDate, "d,M,y");
  if (date.invalid === null) {
    return true;
  }
  date = DateTime.fromFormat(stringDate, "y-M-d");
  if (date.invalid === null) {
    return true;
  }
  date = DateTime.fromFormat(stringDate, "y,M,d");
  if (date.invalid === null) {
    return true;
  }
  date = DateTime.fromFormat(stringDate, "y.M.d");
  if (date.invalid === null) {
    return true;
  }
  date = DateTime.fromFormat(stringDate, "d.M.y");
  if (date.invalid === null) {
    return true;
  }
  date = DateTime.fromFormat(stringDate, "y/M/d");
  if (date.invalid === null) {
    return true;
  }
  date = DateTime.fromFormat(stringDate, "d/M/y");
  if (date.invalid === null) {
    return true;
  }
  date = DateTime.fromFormat(stringDate, "M/d/y");
  if (date.invalid === null) {
    return true;
  }
  return false;
};

export const toCleanISODate = (date) => {
  if (date != null && date.length) {
    return DateTime.fromFormat(date, "M/d/yyyy").toISO();
  } else {
    return false;
  }
};

export const validPrimaryMemberDOB = (date) => {
  if (date != null && date.length) {
    return DateTime.fromFormat(date, "M/d/yyyy").diffNow("years").years < -18;
  } else {
    return false;
  }
};

export const cleanTime = (time) => {
  if (time != null && time.length) {
    return DateTime.fromISO(time).toLocaleString(DateTime.DATE_FULL);
  } else {
    return false;
  }
};

export const cleanISOTime = (time) => {
  if (time != null && time.length) {
    return DateTime.fromFormat(time, "M/d/yyyy").toLocaleString(
      DateTime.DATE_FULL
    );
  } else {
    return false;
  }
};

export const checkIfDateIsLessThan30Minutes = (date) => {
  return DateTime.fromISO(date).diffNow("minutes").minutes > -30;
};

export const validateDateForDependent = (
  type = null,
  date,
  memberLabel,
  dependentLabel,
  allowMinorsAsPrimary = null,
  minMinorPrimaryAge = null
) => {  
  let futureDate = DateTime.fromFormat(date, "M/d/yyyy").toISO() > DateTime.now().toISO();
  if (type == lookupTypes.dependentType.spouse) {
    let spouse = DateTime.fromFormat(date, "M/d/yyyy").diffNow("years").years < -18;
    if (!spouse) {
      return futureDate
        ? "Spouse age must be greater than 18 years and cannot be in the future"
        : "Spouse age must be greater than 18 years";
    } else {
      return true;
    }
  } 
  else if (type == lookupTypes.dependentType.ward) {
    let overEighteenYears = DateTime.fromFormat(date, "M/d/yyyy").diffNow("years").years < -18;
    let underTweentySixYears = DateTime.fromFormat(date, "M/d/yyyy").diffNow("years").years > -26;

    if (!overEighteenYears || !underTweentySixYears) {
      return futureDate
        ? "Ward age must be greater than 18 years, less than 26 years and cannot be in the future"
        : "Ward age must be greater than 18 years and less than 26 years";
    }
    else {
      return true;
    }
  }
  else if (type == lookupTypes.dependentType.dependent) {
    const UnderEighteenYears = DateTime.fromFormat(date, "M/d/yyyy").diffNow("years").years > -18;
    if (!UnderEighteenYears || futureDate) {
      return futureDate
        ? dependentLabel + " age must be less than 18 years and cannot be in the future"
        : dependentLabel + " age must be less than 18 years";
    } else {
      return true;
    }
  }
  else {
    let minAge = 18;
    if(allowMinorsAsPrimary)
      minAge = minMinorPrimaryAge;

    let primaryMember =
      DateTime.fromFormat(date, "M/d/yyyy").diffNow("years").years < -minAge;
    if (!primaryMember) {
      return futureDate
        ? "Primary " +
            memberLabel +
            " age must be greater than " + minAge + " years and cannot be in the future"
        : "Primary " + memberLabel + " age must be greater than " + minAge + " years";
    } else {
      return true;
    }
  }
};

export const getMonthCount = (recordFromDatabase) => {
  let monthsOfYear = Info.months("long", "en");

  let report = [];
  monthsOfYear.forEach((month) => {
    let dataForPreviousYear = recordFromDatabase.find(
      (y) => y.columnName == month && y.type == "previous"
    );
    let dataForCurrentYear = recordFromDatabase.find(
      (y) => y.columnName == month && y.type == "current"
    );
    const previousYear = dataForPreviousYear?.orderer.split("-");
    const currentYear = dataForCurrentYear?.orderer.split("-");
    report.push({
      month: month,
      previousYear: previousYear[0].length > 2 ? previousYear[0] : previousYear[1],
      currentYear: currentYear[0].length > 2 ? currentYear[0] : currentYear[1],
      previousYearData: dataForPreviousYear?.value1 ?? 0,
      currentYearData: dataForCurrentYear?.value1 ?? 0,
    });
  });
  
  return report.sort((a, b) => {
    return a.previousYear.localeCompare(b.previousYear);
  });
};

export const getMonthCount1 = (recordFromDatabase) => {
  let monthsOfYear = Info.months("long", "en");

  let report = [];
  monthsOfYear.forEach((month) => {
    let dataForPreviousYear = recordFromDatabase.filter(
      (y) => y.columnName == month && y.type == "previous"
    );
    let dataForCurrentYear = recordFromDatabase.filter(
      (y) => y.columnName == month && y.type == "current"
    );
    dataForPreviousYear.forEach((data) => {
      const previousYearDate = data?.orderer.split("-");
      if(previousYearDate?.length > 0)
      {
        const previousYear = previousYearDate[0].length > 2 ? previousYearDate[0] : previousYearDate[1];
        const currentYear = (Number(previousYear) + 1).toString();
        report.push({
          month: month,
          previousYear: previousYear,
          previousYearData: data?.value1 ?? 0,
          currentYear: currentYear,
          currentYearData: 0,
        });
      }
    });

    dataForCurrentYear.forEach((data) => {
      const currentYearDate = data?.orderer.split("-");
      if(currentYearDate?.length > 0)
      {
        const currentYear = currentYearDate[0].length > 2 ? currentYearDate[0] : currentYearDate[1];
        const reportData = report.find((x) => x.month == month && x.currentYear == currentYear);
        if(reportData != null)
        {
          reportData.currentYearData = data?.value1 ?? 0;
        }
        else{
          const previousYear = (Number(currentYear) - 1).toString();
          const previousYearData = 0;
          report.push({
            month: month,
            previousYear: previousYear,
            previousYearData: previousYearData,
            currentYear: currentYear,
            currentYearData: data?.value1 ?? 0,
          });
        }
      }
    });
  });
  
  return report.sort((a, b) => {
    return a.previousYear.localeCompare(b.previousYear);
  });
};

export const getShortMonthName = (fullMonthName) => {
  const date = new Date(Date.parse(fullMonthName + " 1, " + new Date().getFullYear()));
  return date.toLocaleString('en-us', { month: 'short' });
}

export const getMinYearMonth = (YearMonthList) => {
  const dateObjects = YearMonthList.map(str => {
    const [month, year] = str.split(' ');
    return new Date(`${month} 1, ${year}`);
  });

  dateObjects.sort((a, b) => a - b);

  const earliestDate = dateObjects[0];
  const earliestMonth = earliestDate.toLocaleString('en-us', { month: 'short' });
  const earliestYear = earliestDate.getFullYear();
  const minMonthYearString = `${earliestMonth} ${earliestYear}`;
  return minMonthYearString;
}

export const getMaxYearMonth = (YearMonthList) => {
  const dateObjects = YearMonthList.map(str => {
    const [month, year] = str.split(' ');
    return new Date(`${month} 1, ${year}`);
  });

  dateObjects.sort((a, b) => b - a);

  const latestDate = dateObjects[0];
  const latestMonth = latestDate.toLocaleString('en-us', { month: 'short' });
  const latestYear = latestDate.getFullYear();
  const maxMonthYearString = `${latestMonth} ${latestYear}`;
  return maxMonthYearString;
}
