import apiClient from "../services/api";
import { defineStore } from "pinia";

export const useEmailStore = defineStore("email", {
  state: () => ({
  }),
  actions: {
    async sendWelcomeEmail(email, password, name, loginUrl) {
      return await apiClient.post(`/email/sendWelcomeEmail?email=${email}&password=${password}&name=${name}&loginURL=${loginUrl}`);
    },
    async sendResetPasswordMail(email, password, name, loginUrl) {
      return await apiClient.post(`/email/sendResetPasswordMail?email=${email}&password=${password}&name=${name}&loginURL=${loginUrl}`);
    },
  },
});