import auth0ApiClient from "../services/auth0Api";
import { defineStore } from "pinia";

export const useAuth0Store = defineStore("auth0", {
  state: () => ({}),
  actions: {
    async getRoles() {
      let data = await auth0ApiClient.get("roles");
      return data.data;
    },
    async getAllRoles() {
      let data = await auth0ApiClient.get("roles");
      return data.data;
    },
    async assignRole(userID, role) {
      await auth0ApiClient.post(`users/${userID}/roles`, role);
    },
    async getUserRoles(userID) {
      const data = await auth0ApiClient.get(`users/${userID}/roles`)
      return data.data;
    },
    async deleteUserRoles(userID, role) {
      await auth0ApiClient.delete(`users/${userID}/roles`,  { data: role });
    },
    async createUser(user) {
      const data = await auth0ApiClient.post("users", user);
      return data.data;
    },
    async getUser(userID) {
      const data = await auth0ApiClient.get(`users/${userID}`);
      return data.data;
    },
    async updateUser(userID, user) {
      const data = await auth0ApiClient.patch(`users/${userID}`, user);
      return data.data;
    },
    async deleteUser(userID) {
      await auth0ApiClient.delete(`users/${userID}`);
    }
  },
});