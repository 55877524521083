<template>
  <section>
    <side-nav-component />
    <v-main>
      <header-component />
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </section>
</template>

<script>
import SideNavComponent from "../components/SideNavComponent.vue";
import HeaderComponent from "../components/HeaderComponent.vue";

export default {
  name: "AuthView",
  components: {
    SideNavComponent,
    HeaderComponent,
  }
};
</script>
