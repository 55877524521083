<template>
    <v-dialog persistent :value="value" @input="$emit('input', $event)">
        <v-card>
            <v-container>
                <!-- <v-card-title> -->
                    <TermsAndConditions />
                <!-- </v-card-title> -->
                <!-- <v-card-title class="justify-center">
                    <span class="font-weight-bold fs-16 text-center">
                        Welcome {{firstName}} {{lastName}}, please accept the terms and conditions before proceeding
                    </span>
                </v-card-title> -->
                <!-- <v-card-text>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, molestias repudiandae! Voluptates quasi quod culpa nesciunt maxime suscipit eos iusto accusantium asperiores hic nostrum quae blanditiis mollitia, vitae reprehenderit doloribus, sit quaerat impedit deserunt fugit! Dolore, odio? Esse, necessitatibus expedita consectetur distinctio explicabo eaque, consequuntur harum blanditiis inventore at fugiat. Quaerat aspernatur laudantium, ut ab praesentium asperiores porro maxime fuga debitis ipsam dolor optio quia aliquid laborum! Necessitatibus, accusantium? Minima magnam vero fuga fugiat praesentium illo officiis, nostrum, assumenda ab quam perspiciatis distinctio atque placeat! Illo, accusantium placeat. Dolore voluptatibus cupiditate dolores rerum quae repudiandae soluta et accusamus reprehenderit necessitatibus!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, molestias repudiandae! Voluptates quasi quod culpa nesciunt maxime suscipit eos iusto accusantium asperiores hic nostrum quae blanditiis mollitia, vitae reprehenderit doloribus, sit quaerat impedit deserunt fugit! Dolore, odio? Esse, necessitatibus expedita consectetur distinctio explicabo eaque, consequuntur harum blanditiis inventore at fugiat. Quaerat aspernatur laudantium, ut ab praesentium asperiores porro maxime fuga debitis ipsam dolor optio quia aliquid laborum! Necessitatibus, accusantium? Minima magnam vero fuga fugiat praesentium illo officiis, nostrum, assumenda ab quam perspiciatis distinctio atque placeat! Illo, accusantium placeat. Dolore voluptatibus cupiditate dolores rerum quae repudiandae soluta et accusamus reprehenderit necessitatibus!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, molestias repudiandae! Voluptates quasi quod culpa nesciunt maxime suscipit eos iusto accusantium asperiores hic nostrum quae blanditiis mollitia, vitae reprehenderit doloribus, sit quaerat impedit deserunt fugit! Dolore, odio? Esse, necessitatibus expedita consectetur distinctio explicabo eaque, consequuntur harum blanditiis inventore at fugiat. Quaerat aspernatur laudantium, ut ab praesentium asperiores porro maxime fuga debitis ipsam dolor optio quia aliquid laborum! Necessitatibus, accusantium? Minima magnam vero fuga fugiat praesentium illo officiis, nostrum, assumenda ab quam perspiciatis distinctio atque placeat! Illo, accusantium placeat. Dolore voluptatibus cupiditate dolores rerum quae repudiandae soluta et accusamus reprehenderit necessitatibus!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, molestias repudiandae! Voluptates quasi quod culpa nesciunt maxime suscipit eos iusto accusantium asperiores hic nostrum quae blanditiis mollitia, vitae reprehenderit doloribus, sit quaerat impedit deserunt fugit! Dolore, odio? Esse, necessitatibus expedita consectetur distinctio explicabo eaque, consequuntur harum blanditiis inventore at fugiat. Quaerat aspernatur laudantium, ut ab praesentium asperiores porro maxime fuga debitis ipsam dolor optio quia aliquid laborum! Necessitatibus, accusantium? Minima magnam vero fuga fugiat praesentium illo officiis, nostrum, assumenda ab quam perspiciatis distinctio atque placeat! Illo, accusantium placeat. Dolore voluptatibus cupiditate dolores rerum quae repudiandae soluta et accusamus reprehenderit necessitatibus!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, molestias repudiandae! Voluptates quasi quod culpa nesciunt maxime suscipit eos iusto accusantium asperiores hic nostrum quae blanditiis mollitia, vitae reprehenderit doloribus, sit quaerat impedit deserunt fugit! Dolore, odio? Esse, necessitatibus expedita consectetur distinctio explicabo eaque, consequuntur harum blanditiis inventore at fugiat. Quaerat aspernatur laudantium, ut ab praesentium asperiores porro maxime fuga debitis ipsam dolor optio quia aliquid laborum! Necessitatibus, accusantium? Minima magnam vero fuga fugiat praesentium illo officiis, nostrum, assumenda ab quam perspiciatis distinctio atque placeat! Illo, accusantium placeat. Dolore voluptatibus cupiditate dolores rerum quae repudiandae soluta et accusamus reprehenderit necessitatibus!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, molestias repudiandae! Voluptates quasi quod culpa nesciunt maxime suscipit eos iusto accusantium asperiores hic nostrum quae blanditiis mollitia, vitae reprehenderit doloribus, sit quaerat impedit deserunt fugit! Dolore, odio? Esse, necessitatibus expedita consectetur distinctio explicabo eaque, consequuntur harum blanditiis inventore at fugiat. Quaerat aspernatur laudantium, ut ab praesentium asperiores porro maxime fuga debitis ipsam dolor optio quia aliquid laborum! Necessitatibus, accusantium? Minima magnam vero fuga fugiat praesentium illo officiis, nostrum, assumenda ab quam perspiciatis distinctio atque placeat! Illo, accusantium placeat. Dolore voluptatibus cupiditate dolores rerum quae repudiandae soluta et accusamus reprehenderit necessitatibus!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, molestias repudiandae! Voluptates quasi quod culpa nesciunt maxime suscipit eos iusto accusantium asperiores hic nostrum quae blanditiis mollitia, vitae reprehenderit doloribus, sit quaerat impedit deserunt fugit! Dolore, odio? Esse, necessitatibus expedita consectetur distinctio explicabo eaque, consequuntur harum blanditiis inventore at fugiat. Quaerat aspernatur laudantium, ut ab praesentium asperiores porro maxime fuga debitis ipsam dolor optio quia aliquid laborum! Necessitatibus, accusantium? Minima magnam vero fuga fugiat praesentium illo officiis, nostrum, assumenda ab quam perspiciatis distinctio atque placeat! Illo, accusantium placeat. Dolore voluptatibus cupiditate dolores rerum quae repudiandae soluta et accusamus reprehenderit necessitatibus!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, molestias repudiandae! Voluptates quasi quod culpa nesciunt maxime suscipit eos iusto accusantium asperiores hic nostrum quae blanditiis mollitia, vitae reprehenderit doloribus, sit quaerat impedit deserunt fugit! Dolore, odio? Esse, necessitatibus expedita consectetur distinctio explicabo eaque, consequuntur harum blanditiis inventore at fugiat. Quaerat aspernatur laudantium, ut ab praesentium asperiores porro maxime fuga debitis ipsam dolor optio quia aliquid laborum! Necessitatibus, accusantium? Minima magnam vero fuga fugiat praesentium illo officiis, nostrum, assumenda ab quam perspiciatis distinctio atque placeat! Illo, accusantium placeat. Dolore voluptatibus cupiditate dolores rerum quae repudiandae soluta et accusamus reprehenderit necessitatibus!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, molestias repudiandae! Voluptates quasi quod culpa nesciunt maxime suscipit eos iusto accusantium asperiores hic nostrum quae blanditiis mollitia, vitae reprehenderit doloribus, sit quaerat impedit deserunt fugit! Dolore, odio? Esse, necessitatibus expedita consectetur distinctio explicabo eaque, consequuntur harum blanditiis inventore at fugiat. Quaerat aspernatur laudantium, ut ab praesentium asperiores porro maxime fuga debitis ipsam dolor optio quia aliquid laborum! Necessitatibus, accusantium? Minima magnam vero fuga fugiat praesentium illo officiis, nostrum, assumenda ab quam perspiciatis distinctio atque placeat! Illo, accusantium placeat. Dolore voluptatibus cupiditate dolores rerum quae repudiandae soluta et accusamus reprehenderit necessitatibus!
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta, molestias repudiandae! Voluptates quasi quod culpa nesciunt maxime suscipit eos iusto accusantium asperiores hic nostrum quae blanditiis mollitia, vitae reprehenderit doloribus, sit quaerat impedit deserunt fugit! Dolore, odio? Esse, necessitatibus expedita consectetur distinctio explicabo eaque, consequuntur harum blanditiis inventore at fugiat. Quaerat aspernatur laudantium, ut ab praesentium asperiores porro maxime fuga debitis ipsam dolor optio quia aliquid laborum! Necessitatibus, accusantium? Minima magnam vero fuga fugiat praesentium illo officiis, nostrum, assumenda ab quam perspiciatis distinctio atque placeat! Illo, accusantium placeat. Dolore voluptatibus cupiditate dolores rerum quae repudiandae soluta et accusamus reprehenderit necessitatibus!
                    </p>
                </v-card-text> -->
                <v-card-actions>
                    <!-- <v-spacer></v-spacer> -->
                    <v-container fill-height>
                        <v-row justify="center" align="center" class="fill-height">
                            <div>
                                <v-switch label="I agree to the above Terms and Conditions" v-model.trim="agreedToTerms"></v-switch>
                                <v-btn color="success" :disabled="!agreedToTerms" class="ma-2" @click="$emit('input')">
                                    I Agree
                                </v-btn>
                                <v-btn color="cancel" dark class="ma-2" @click="cancelTerms">
                                    I Disagree
                                </v-btn>
                            </div>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-container>
        </v-card>
    </v-dialog>
</template>

<script>
import { usePublicApiStore } from "../../stores/public";
import TermsAndConditions from "./TermsAndConditions.vue"

export default {
    setup() {
        const publicApiStore = usePublicApiStore();
        return { publicApiStore };
    },
    props:['value', 'firstName', 'lastName', 'inviteID'],
    name: 'Dialog',
    components: { TermsAndConditions },
    data() {
        return {
            agreedToTerms: false,
            cancelInvitation: false,
            docUrl: '../../assets/Online_Employer_Agreement.docx'
        }
    },
    methods: {
        cancelTerms() {
            return this.$swal({
                title: "Warning",
                icon: "warning",
                text: "You cannot proceed with completing your sign Up without accepting the Terms and conditions",
                buttons: true,
                showDenyButton: true,
                confirmButtonColor: this.$vuetify.theme.themes.light.success,
                denyButtonColor: this.$vuetify.theme.themes.light.deny,
                confirmButtonText: "Continue",
                denyButtonText: "Cancel",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.publicApiStore.cancelInvite(this.inviteID);
                    this.$emit('cancel', 'setToFalse')
                    // this.$router.push("/");
                    document.location.href="/";
                }
            });
        }
    }
}
</script>