import apiClient from "../services/api";
import { defineStore } from "pinia";

export const useReportStore = defineStore("report", {
  state: () => ({}),
  getters: {},
  actions: {
    async getMemberListReportByEmployerID(employerID){
      const data = await apiClient.get(`/report/getMemberListReportByEmployerID?employerID=${employerID}`);
      return data.data;
    }, 
    async getProductUtilizationByEmployer(employerID, dynamicFilter){
      const url = addDynamicFilterQueryParam(`/report/getProductUtilizationByEmployer?employerID=${employerID}`, dynamicFilter);
      const data = await apiClient.get(url);
      return data.data;
    }, 
    async getTierCountsByEmployeeID(employerID, dynamicFilter){
      const url = addDynamicFilterQueryParam(`/report/getTierCountsByEmployeeID?employerID=${employerID}`, dynamicFilter);
      const data = await apiClient.get(url);
      return data.data;
    }, 
    async getMemberActivityByEmployerID(employerID, dynamicFilter){
      let url = addDynamicFilterQueryParam(`/report/getMemberActivityByEmployerID?employerID=${employerID}`, dynamicFilter);
      const data = await apiClient.get(url);
      return data.data;
    },
    async getSelfServiceReport(employerID){
      const data = await apiClient.get(`/report/getSelfServiceReport?employerID=${employerID}`);
      return data.data;
    },
    async getMemberVisitReport(employerID, dynamicFilter){
      const url = addDynamicFilterQueryParam(`/report/getMemberVisitReport?employerID=${employerID}`, dynamicFilter)
      const data = await apiClient.get(url);
      return data.data;      
    },
    async getMembersRxReport(employerID, dynamicFilter){
      const url = addDynamicFilterQueryParam(`/report/getMembersRxVisitReport?employerID=${employerID}`, dynamicFilter)
      const data = await apiClient.get(url);
      return data.data;      
    },
    async getAnnualizeVisitReport(employerID, from, to, dynamicFilter){
      let url = addDynamicFilterQueryParam(`/report/getAnnualizeVisitReport?employerID=${employerID}&dateFrom=${from}&dateTo=${to}`, dynamicFilter);
      const data = await apiClient.get(url);
      return data.data;      
    },
    async getReportForVisitsPerMonth(employerID, from, to, dynamicFilter){
      const url = addDynamicFilterQueryParam(`/report/getReportForVisitsPerMonth?employerID=${employerID}&dateFrom=${from}&dateTo=${to}`, dynamicFilter);
      const data = await apiClient.get(url);
      return data.data;      
    },
    async getPrescriptionTrendReport(employerID, from, to, dynamicFilter){
      let url = addDynamicFilterQueryParam(`/report/getPrescriptionTrendReport?employerID=${employerID}&dateFrom=${from}&dateTo=${to}`, dynamicFilter);
      const data = await apiClient.get(url);
      return data.data;
    },
    async getPrescriptionsPerMonthReport(employerID, from, to, dynamicFilter){
      let url = addDynamicFilterQueryParam(`/report/getPrescriptionsPerMonthReport?employerID=${employerID}&dateFrom=${from}&dateTo=${to}`, dynamicFilter);
      const data = await apiClient.get(url);
      return data.data;    
    },
    async getUtilizationByProductReport(employerID, from, to, dynamicFilter) {
      let url = addDynamicFilterQueryParam(`/report/getUtilizationByProduct?employerID=${employerID}&dateFrom=${from}&DateTo=${to}`, dynamicFilter);
      const data = await apiClient.get(url);
      return data.data;
    },
    async getMostPrescribedMedicationsReport(employerID, from, to){
      const data = await apiClient.get(`/report/getMostPrescribedMedications?employerID=${employerID}&from=${from}&to=${to}`);
      return data.data;    
    },
    async getDashboardChartReport(employerID){
      const data = await apiClient.get(`/report/getDashboardChartReport?employerID=${employerID}`);
      return data.data;    
    },
    async getDashboardHouseHoldReport(employerID){
      const data = await apiClient.get(`/report/getDashboardHouseHoldReport?employerID=${employerID}`);
      return data.data;    
    },
    async getDashboardMapReport(employerID){
      const data = await apiClient.get(`/report/getDashboardMapReport?employerID=${employerID}`);
      return data.data;    
    },
    async getMemberSurveyReport(employerID, from, to){
      const data = await apiClient.get(`/report/getMemberSurveyReport?employerID=${employerID}&from=${from}&to=${to}`);
      return data.data;    
    },
    async getMostCommonConditionsReport(employerID, from, to){
      const data = await apiClient.get(`/report/getMostCommonConditions?employerID=${employerID}&from=${from}&to=${to}`);
      return data.data;    
    },
    async getRxFillMethodReport(employerID, from, to, dynamicFilter){
      let url = addDynamicFilterQueryParam(`/report/getRxFillMethodReport?employerID=${employerID}&dateFrom=${from}&dateTo=${to}`, dynamicFilter);
      const data = await apiClient.get(url);
      return data.data;    
    },
    async checkEmployerHasUrgentCare(employerID){
      const data = await apiClient.get(`/report/checkEmployerHasUrgentCare?employerID=${employerID}`);
      return data.data;    
    },
    async getVisitDistributionReport(employerID, dynamicFilter){
      let url = addDynamicFilterQueryParam(`/report/getVisitDistributionReport?employerID=${employerID}`, dynamicFilter);
      const data = await apiClient.get(url);
      return data.data;    
    },
  },
});

const addDynamicFilterQueryParam = (url, dynamicFilterObj) => {
  url += dynamicFilterObj && Object.keys(dynamicFilterObj).length > 0 ? `&dynamicFilter=${JSON.stringify(dynamicFilterObj)}` : '';
  return url;
}