<template>
  <v-container>
    <v-card>
      <v-card-title> View {{employerStore.fieldLabelSettings.subscriberLabel}} <v-icon> </v-icon> </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-form v-model="emloyeeIsValid" ref="employeeListForm">
              <v-row>
                <v-col cols="9">
                  <v-autocomplete :label="'Select ' + employerStore.fieldLabelSettings.groupLabel" v-model.trim="selectedEmployer" :items="employerList" :item-text="(item) => item.name" :item-value="(item) => item.employerID" disabled></v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-btn depressed color="success" @click="restoreMember(member)">
                    Restore
                  </v-btn>
                </v-col>
              </v-row> 
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="selected">
          <v-col cols="12">
            <p class="font-weight-bold primary--text mt-3" bold>
              Primary {{employerStore.fieldLabelSettings.subscriberLabel}} Demographics:
            </p>
          </v-col>
          <v-col cols="12" class="ml-2">
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field v-model.trim="member.firstName" label="First Name" disabled></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model.trim="member.middleName" label="Middle Name" disabled></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model.trim="member.lastName" label="Last Name" disabled></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field v-model.trim="member.externalId" label="External ID" disabled></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field v-model.trim="member.email" label="E-mail" disabled></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <label for="phonePrimary">Primary Phone</label>
                  <vue-tel-input id="phonePrimary" v-model.trim="member.phonePrimary" disabled/>
                </v-col>

                <v-col cols="12" md="4">
                  <label for="phoneSecondary">Secondary Phone</label>
                  <vue-tel-input id="phoneSecondary" v-model.trim="member.phoneSecondary" disabled/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-select v-model.trim="member.gender" :items="genderList" :item-text="(item) => item.name" :item-value="(item) => item.lookupID" label="Gender" disabled></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field v-model.trim="member.dob" label="Date of Birth" v-maska="'##/##/####'" hint="MM/DD/YYYY" disabled></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select v-model.trim="member.language" :items="languages" :item-text="(item) => item.text" :item-value="(item) => item.value" label="Preferred Language" disabled></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="member.primaryStreet" label="Address Line 1" disabled></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="member.primaryStreetTwo" label="Address Line 2" disabled></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model.trim="member.primaryCity" label="City" disabled></v-text-field>
                </v-col>
                <v-col cols="12" md="6"> </v-col>
              </v-row>

              <v-row class="mb-5">
                <v-col cols="12" md="6">
                  <v-select
                    v-model.trim="member.primaryRegion" :items="states" :item-text="(item) => item.name" :item-value="(item) => item.abbreviation" label="Select Region" disabled>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model.trim="member.primaryPostal" disabled label="Postal Code"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import states from "../../../state.json";
import { useMemberStore } from "../../stores/member";
import { useEmployerStore } from "../../stores/employer";
import { useProductStore } from "../../stores/product";
import { useLookupStore } from "../../stores/lookup";
// import { convertToMemberObject } from "../../commons/member";
import { lookupTypes } from "../../data/definitions/lookupTypes";
import { cleanDate } from "../../commons/date";
import { DateTime } from "luxon";

export default {
  name: "EditMemberComponent",

  setup() {
    const memberStore = useMemberStore();
    const productStore = useProductStore();
    const employerStore = useEmployerStore();
    const lookupStore = useLookupStore();
    return {
      memberStore,
      employerStore,
      productStore,
      lookupStore,
      cleanDate,
      states,
    };
  },
  computed: {
    employerList: {
      get() {
        return this.employerStore.employerList;
      },
    },
    genderList: {
      get() {
        return this.lookupStore.genderList;
      },
    },
  },
  data() {
    return {
      fullMemberObject: {},
      emloyeeIsValid: true,
      valid: true,
      member: {
        primaryCountry: "US",
        primaryRegion: "",
        firstName: "",
        middleName: "",
        lastName: "",
        externalId: "",
        phonePrimary: "",
        phoneSecondary: "",
        dob: "",
        email: "",
        primaryStreet: "",
        primaryStreetTwo: "",
        primaryPostal: "",
        language: "",
        primaryCity: "",
        gender: "",
        isDeleted: false,
        memberID: ""
      },
      //employer data
      selected: false,
      selectedEmployer: "",
      languages: [
        { text: "English", value: "en-us" },
        { text: "Spanish", value: "es-mx" },
      ],
      removedProducts: [],
      isDirty: false
    };
  },
  watch: {
    selectedEmployer(selectedEmployer) {
      if (selectedEmployer) {
        this.selected = true;
      }
    },
  },
  mounted() {
    if (this.$route.query.memberID) {
      this.memberStore
        .getFullMemberByID(this.$route.query.memberID)
        .then((res) => {
          this.fullMemberObject = res;
          //get employer id
          this.selectedEmployer = this.fullMemberObject.memberEmployer.employerID;
          //parse object to editable object
          const findExternal = this.fullMemberObject.memberAttributes.find((x) => x.attributeType == lookupTypes.attributes.externalID);
          const findLanguage = this.fullMemberObject.memberAttributes.find((x) => x.attributeType == lookupTypes.memberType.language);
          let externalID = findExternal ? findExternal.attributeValue : "";
          let language = findLanguage ? findLanguage.attributeValue : "";

          // load employer's list
          this.employerStore.getAllEmployersByActor();

          //for determining the dependent type, query the lookup/getMemberTypeList endpoint
          this.lookupStore.getGenderList();

          this.fullMemberObject.member.dob = DateTime.fromISO(this.fullMemberObject.member.dateOfBirth).toFormat("MM/dd/yyyy");
          this.fullMemberObject.member.externalId = externalID;
          this.fullMemberObject.member.language = language;
          
          this.member = this.fullMemberObject.member;
        });
    } else {
      this.$swal({
        title: "Error",
        text: "Invalid Page",
        icon: "error",
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Go Back",
      }).then((okay) => {
        if (okay) {
          this.$router.push({ name: "subscriber_admin_index" });
        }
      });
    }
  },
  methods: {
    restoreMember(member)
    {
      this.$swal({
        title: "Warning",
        icon: "warning",
        text: member.parentID ? 'Reactivating this record will activate the primary as well. ' : `This will reactivate the ${this.employerStore.fieldLabelSettings.subscriberLabel} .` + 'Are you sure you wish to continue?',
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        showDenyButton: true,
        confirmButtonText: "Continue",
        denyButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed)
          this.memberStore.UnDeleteMember(member).then(() => { this.$router.push({ name: "subscriber_admin_edit", query: { memberID: this.$route.query.memberID } });})
      }); 
    }
  },
};
</script>
