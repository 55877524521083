import apiClient from "../services/api";

import { defineStore } from "pinia";

export const useProductStore = defineStore("product", {
  state: () => ({
  }),
  getters: {

  },
  actions: {
    async getProductsByEmployer(employerID) {
      try {
        return await apiClient.get("/product/getProductsByEmployer?employerID=" + employerID);
      }
      catch{
        return [];
      }
    },
    async getProductStockByEmployer(employerID) {
      try {
        return await apiClient.get("/product/getProductStockByEmployer?employerID=" + employerID);
      }
      catch{
        return [];
      }
    },
    async getAllProductStock() {
      try {
        const products =  await apiClient.get("/product/getAllProductStock");
        return products.data;
      }
      catch{
        return [];
      }
    },
    async getVisitLimitByemployer(employerID){
      try {
        const products = await apiClient.get(`/product/GetVisitLimitByemployer${employerID ? `?employerID=${employerID}` : ''}`);        
        return products.data;
      }
      catch{
        return [];
      }
    }
  },
});
