<template>
  <v-container>
    <header-component />
    <div class="text-center">
      <h2 class="mt-10 secondary--text">Welcome to the ReviveHealth Sponsor Portal</h2>
      <h3 class="mt-10 primary--text">
        At ReviveHealth we believe that people should have simple and convenient access when it comes to 
        their healthcare. We also believe that as a user you should have access to a platform that is easy to use 
        and provides you with the information you need at your fingertips. Your access portal is designed to 
        give you flexibility to access and configure information that is relevant to you.
      </h3>
      <h3 class="mt-10 primary--text">
        Thank you for being a part of ReviveHealth
      </h3>
      <v-row class="text-center mt-10" justify="center">
        <v-col md="3">
          <div v-if="!$auth.isAuthenticated" @click="login" class="pa-4 text-no-wrap rounded-pill black-border primary--text">
            Login
          </div>
          <div v-else @click="logout" class="pa-4 text-no-wrap rounded-pill black-border primary--text">
            Logout
          </div> 
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import HeaderComponent from "./HeaderComponent.vue";

export default {
  name: "HomeComponent",
  components: { HeaderComponent },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
  },
};
</script>

<style>
.black-border{
  border: 1px solid black;
  background-color: #7cbf9a;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.black-border:hover{
  opacity: 0.6;
  cursor: pointer;
}
</style>