<template>
    <v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
     
        <v-card-title class="justify-space-between">
          {{employerStore.fieldLabelSettings.groupLabel}} Upload Settings
        </v-card-title>
        <v-card-text>
          <div
            class="align-center" v-if="!loadingCompleted">
                <v-progress-circular
                    v-if="!loadingCompleted"
                    indeterminate
                    color="#223047"></v-progress-circular>
                <p>Loading</p>
            </div>

            <v-row v-if="loadingCompleted">
                <v-col cols="12">
                    <b>Instructions: </b> 
                    <p> 
                        <ul>
                            <li>Please select if your file includes a header with column names or not.</li>
                            <li>Please upload a file of your enrollment template.</li>
                            <li>Field selection below will populate and allow you map your column names or positions to the fields in our database.</li>
                            <li>Review the table below the selection list to determine if you have mapped your fields correctly.</li>
                        </ul>
                        
                    </p>
                </v-col>

                <v-col cols="12">
                    <b>Options: </b> 
                </v-col>
                <v-col cols="3">
                    <v-card
                    height="50"
                    class=" pl-sm-5 d-flex justify-space-between align-center rounded-lg"
                    >
                        <v-switch v-model.trim="useFileHeaders" :disabled="uploadComplete" label="Use File Headers "></v-switch>
                    </v-card>
                </v-col>

                <v-col sm="6">
                    <v-card
                    height="50"
                    class=" pl-sm-5 d-flex justify-space-between align-center rounded-lg"
                    >
                    <span
                        class="d-sm-flex"
                        >Select File Mapping Type</span
                    >
                    <v-radio-group
                        id="mappingTypeFor-text"
                        ref="mappingType"
                        v-model="mappingType"
                        :disabled="uploadComplete"
                        mandatory
                    >
                        <span class="d-flex">
                        <v-radio
                            label="Flat File"
                            :value="1"
                            color="info"
                            class="my-0 mx-2"
                        ></v-radio>
                        <v-radio
                            label="Primary As Relationship"
                            :value="2"
                            color="info"
                            class="my-0 mx-2"
                        ></v-radio>
                        </span>
                    </v-radio-group>
                    </v-card>
                </v-col>

                <v-col cols="8"  v-if="isNewTemplate">
                    <v-file-input show-size label="Upload Template" accept=".csv,.txt" truncate-length="50" v-model.trim="mappingFile"></v-file-input>
                </v-col>
                <v-col cols="2" v-if="mappingFile">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="accept" class="white--text ma-2" v-bind="attrs" v-on="on" @click="previewFile" >Upload Template</v-btn>
                        </template>
                        <span>Upload template to map the file.</span>
                    </v-tooltip>
                </v-col>
                
            </v-row>

    <v-form v-model="isFormValid" ref="formUpload">

        <!-- 
            Mapping Flat File views
        -->   
            <div v-if="showFlat">

            <!-- 
                Custom Formats
            -->            
            <div class="mx-auto pb-10  py-10">
                    <h3 class="mb-3">Formats</h3>
                    <v-row>
                        <!-- Date Format -->
                        <v-col sm="6" >
                            <v-row>
                                <v-col v-if="flatFileColumns.dateFormat">
                                    <v-row>
                                        <v-col sm="11">
                                            <v-select
                                                class="pt-1 pl-sm-6"
                                                v-model.trim="flatFileColumns.dateFormat" 
                                                :items="dateTimeFormats"
                                                item-text="name"
                                                item-value="valueType"
                                                label="Date Format"
                                                :disabled="true"
                                                >
                                            </v-select>
                                        </v-col>
                                        <v-col sm="1" class="mt-3">
                                            <v-icon v-on:click="flatFileColumns.dateFormat = ''">
                                                mdi-close
                                            </v-icon>
                                        </v-col>
                                        </v-row>
                                </v-col>     
                                <v-col sm="12" v-if="!flatFileColumns.dateFormat">
                                    <span class="d-flex">
                                        <v-select
                                            id="employeeDateFormat-text"
                                            ref="employeeDateFormat"
                                            class="pt-1 pl-sm-6"
                                            v-model.trim="flatFileColumns.dateFormat" 
                                            :items="dateTimeFormats"
                                            item-text="name"
                                            item-value="valueType"
                                            label="Date Format"
                                            >
                                        </v-select>
                                    </span>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- Gender Format -->
                        <v-col sm="6" >
                            <v-row>
                                <v-col v-if="flatFileColumns.genderFormat">
                                    <v-row>
                                        <v-col sm="11">
                                            <v-select
                                                class="pt-1 pl-sm-6"
                                                v-model.trim="flatFileColumns.genderFormat" 
                                                :items="genderFormats"
                                                item-text="name"
                                                item-value="valueType"
                                                label="Gender Format"
                                                :disabled="true"
                                                >
                                            </v-select>
                                        </v-col>
                                        <v-col sm="1" class="mt-3">
                                            <v-icon v-on:click="flatFileColumns.genderFormat = ''">
                                                mdi-close
                                            </v-icon>
                                        </v-col>
                                        </v-row>
                                </v-col>     
                                <v-col sm="12" v-if="!flatFileColumns.genderFormat">
                                    <span class="d-flex">
                                        <v-select
                                            id="employeeGenderFormat-text"
                                            ref="employeeGenderFormatt"
                                            class="pt-1 pl-sm-6"
                                            v-model.trim="flatFileColumns.genderFormat" 
                                            :items="genderFormats"
                                            item-text="name"
                                            item-value="valueType"
                                            label="Gender Format"
                                            >
                                        </v-select>
                                    </span>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- File Delimiter Format -->
                        <v-col sm="6" >
                            <v-row>
                                <v-col v-if="flatFileColumns.fileDelimiterFormat">
                                    <v-row>
                                        <v-col sm="11">
                                            <v-select
                                                class="pt-1 pl-sm-6"
                                                v-model.trim="flatFileColumns.fileDelimiterFormat" 
                                                :items="fileDelimiterFormats"
                                                item-text="name"
                                                item-value="valueType"
                                                label="File Delimiter Format"
                                                :disabled="true"
                                                >
                                            </v-select>
                                        </v-col>
                                        <v-col sm="1" class="mt-3">
                                            <v-icon v-on:click="flatFileColumns.fileDelimiterFormat = ''">
                                                mdi-close
                                            </v-icon>
                                        </v-col>
                                        </v-row>
                                </v-col>     
                                <v-col sm="12" v-if="!flatFileColumns.fileDelimiterFormat">
                                    <span class="d-flex">
                                        <v-select
                                            id="employeeFileDelimiterFormat-text"
                                            ref="employeeFileDelimiterFormat"
                                            class="pt-1 pl-sm-6"
                                            v-model.trim="flatFileColumns.fileDelimiterFormat" 
                                            :items="fileDelimiterFormats"
                                            item-text="name"
                                            item-value="valueType"
                                            label="File Delimiter Format"
                                            >
                                        </v-select>
                                    </span>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>
            </div> 

            <!-- 
                Employer
            -->            
            <div class="mx-auto pb-10">
                <h3 class="mb-3">Employer</h3>        
                <v-row>
                    <!-- Name  -->
                    <v-col sm="6" >
                        <v-row>
                            <v-col v-if="flatFileColumns.employer.name != ''">
                                <v-row>
                                    <v-col sm="11">
                                        <v-select
                                            class="pt-1 pl-sm-6"
                                            v-model.trim="flatFileColumns.employer.name" 
                                            :items="selectedColumns"
                                            :item-text="name"
                                            :item-value="name"
                                            label="Name"
                                            :disabled="true"
                                            >
                                        </v-select>
                                    </v-col>
                                    <v-col sm="1" class="mt-3">
                                        <v-icon v-on:click="handleClearSelection(flatFileColumns.employer.name); flatFileColumns.employer.name = ''">
                                            mdi-close
                                        </v-icon>
                                    </v-col>
                                 </v-row>
                            </v-col>     
                            <v-col sm="12" v-if="flatFileColumns.employer.name == ''">
                                <span class="d-flex">
                                    <v-select
                                        id="employerFirstName-text"
                                        ref="employerFirstName"
                                        class="pt-1 pl-sm-6"
                                        v-model.trim="flatFileColumns.employer.name" 
                                        :items="mappingArrayNaming"
                                        item-text="name"
                                        item-value="name" 
                                        @input="handleSelect(`${flatFileColumns.employer.name}`, 'Employer Name')"
                                        label="Name"
                                        >
                                    </v-select>
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>
                    
                    <!-- Contact  -->

                    <v-col sm="6" >
                        <v-row>
                            <v-col v-if="flatFileColumns.employer.contact != ''">
                                <v-row>
                                    <v-col sm="11">
                                        <v-select
                                            class="pt-1 pl-sm-6"
                                            v-model.trim="flatFileColumns.employer.contact" 
                                            :items="selectedColumns"
                                            :item-text="contact"
                                            :item-value="contact"
                                            label="Contact"
                                            :disabled="true"
                                            >
                                        </v-select>
                                    </v-col>
                                    <v-col sm="1" class="mt-3">
                                        <v-icon v-on:click="handleClearSelection(flatFileColumns.employer.contact); flatFileColumns.employer.contact = ''">
                                            mdi-close
                                        </v-icon>
                                    </v-col>
                                 </v-row>
                            </v-col>     
                            <v-col sm="12" v-if="flatFileColumns.employer.contact == ''">
                                <span class="d-flex">
                                    <v-select
                                    id="employerContact-text"
                                        ref="employerContact"
                                        class="pt-1 pl-sm-6"
                                        v-model.trim="flatFileColumns.employer.contact" 
                                        :items="mappingArrayNaming"
                                        item-text="contact"
                                        item-value="contact" 
                                        @input="handleSelect(`${flatFileColumns.employer.contact}`, 'Employer Contact')"
                                        label="Contact"
                                        >
                                    </v-select>
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>           
                </v-row>
            </div>
            
            <!-- 
                Product
            -->            
                <div class="mx-auto pb-10">
                    <h3 class="mb-3">Product</h3>        
                    <v-row>
                        <!-- product  -->
                        <v-col sm="6" >
                        <v-row>
                            <v-col v-if="flatFileColumns.product.product != ''">
                                <v-row>
                                    <v-col sm="11">
                                        <v-select
                                            class="pt-1 pl-sm-6"
                                            v-model.trim="flatFileColumns.product.product" 
                                            :items="selectedColumns"
                                            :item-text="product"
                                            :item-value="product"
                                            label="Product"
                                            :disabled="true"
                                            >
                                        </v-select>
                                    </v-col>
                                    <v-col sm="1" class="mt-3">
                                        <v-icon v-on:click="handleClearSelection(flatFileColumns.product.product); flatFileColumns.product.product = ''">
                                            mdi-close
                                        </v-icon>
                                    </v-col>
                                 </v-row>
                            </v-col>     
                            <v-col sm="12" v-if="flatFileColumns.product.product == ''">
                                <span class="d-flex">
                                    <v-select
                                        id="product-text"
                                        ref="product"
                                        class="pt-1 pl-sm-6"
                                        v-model.trim="flatFileColumns.product.product" 
                                        :items="mappingArrayNaming"
                                        item-text="product"
                                        item-value="product" 
                                        @input="handleSelect(`${flatFileColumns.product.product}`, 'Product')"
                                        label="Product"
                                        >
                                    </v-select>
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>  
                    
                    <!-- product start  -->
                    <v-col sm="6" >
                        <v-row>
                            <v-col v-if="flatFileColumns.product.productStart != ''">
                                <v-row>
                                    <v-col sm="11">
                                        <v-select
                                            class="pt-1 pl-sm-6"
                                            v-model.trim="flatFileColumns.product.productStart" 
                                            :items="selectedColumns"
                                            :item-text="productStart"
                                            :item-value="productStart"
                                            label="Product Start Date"
                                            :disabled="true"
                                            >
                                        </v-select>
                                    </v-col>
                                    <v-col sm="1" class="mt-3">
                                        <v-icon v-on:click="handleClearSelection(flatFileColumns.product.productStart); flatFileColumns.product.productStart = ''">
                                            mdi-close
                                        </v-icon>
                                    </v-col>
                                 </v-row>
                            </v-col>     
                            <v-col sm="12" v-if="flatFileColumns.product.productStart == ''">
                                <span class="d-flex">
                                    <v-select
                                        id="productStart-text"
                                        ref="productStart"
                                        class="pt-1 pl-sm-6"
                                        v-model.trim="flatFileColumns.product.productStart" 
                                        :items="mappingArrayNaming"
                                        item-text="productStart"
                                        item-value="productStart" 
                                        @input="handleSelect(`${flatFileColumns.product.productStart}`, 'Product Start')"
                                        label="Product Start Date"
                                        >
                                    </v-select>
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- product End -->
                    <v-col sm="6" >
                        <v-row>
                            <v-col v-if="flatFileColumns.product.productEnd != ''">
                                <v-row>
                                    <v-col sm="11">
                                        <v-select
                                            class="pt-1 pl-sm-6"
                                            v-model.trim="flatFileColumns.product.productEnd" 
                                            :items="selectedColumns"
                                            :item-text="productEnd"
                                            :item-value="productEnd"
                                            label="Product End Date"
                                            :disabled="true"
                                            >
                                        </v-select>
                                    </v-col>
                                    <v-col sm="1" class="mt-3">
                                        <v-icon v-on:click="handleClearSelection(flatFileColumns.product.productEnd); flatFileColumns.product.productEnd = ''">
                                            mdi-close
                                        </v-icon>
                                    </v-col>
                                 </v-row>
                            </v-col>     
                            <v-col sm="12" v-if="flatFileColumns.product.productEnd == ''">
                                <span class="d-flex">
                                    <v-select
                                        id="productEnd-text"
                                        ref="productEnd"
                                        class="pt-1 pl-sm-6"
                                        v-model.trim="flatFileColumns.product.productEnd" 
                                        :items="mappingArrayNaming"
                                        item-text="productEnd"
                                        item-value="productEnd" 
                                        @input="handleSelect(`${flatFileColumns.product.productEnd}`, 'Product End')"
                                        label="Product End Date"
                                        >
                                    </v-select>
                                </span>
                            </v-col>
                        </v-row>
                    </v-col>

                    </v-row>
                </div>

            <!-- 
                Employee
            -->            
                <div class="mx-auto pb-10">
                    <h3 class="mb-3">Employee</h3>
                    <EmployeeMapping :activeColumns="activeColumns" :member="flatFileColumns.employee" :useFileHeaders="useFileHeaders"
                        :memberType="primary" :selectedColumns="selectedColumns" :mappingList="mappingArrayNaming" />
                </div> 

            <!-- 
                Spouse
            -->            
                <div class="mx-auto pb-10">
                    <h3 class="mb-3">Spouse
                        <span v-if="this.flatFileColumns.spouse.firstName != null">
                            <v-icon v-on:click="handleSpouseRemove(index)">
                                mdi-close-thick
                            </v-icon>
                        </span>
                    </h3>
                    
                    <v-row>
                    <v-col sm="12" class="d-flex justify-center justify-md-end px-0" v-if="this.flatFileColumns.spouse.firstName == null">
                    <v-btn
                        color="primary"
                        v-on:click="handleAddSpouse"
                        id="adult-add"
                        > Add Spouse</v-btn>
                    </v-col>
                    <v-col sm="12" v-if="this.flatFileColumns.spouse.firstName != null">
                        <DependentMapping :activeColumns="activeColumns" :member="flatFileColumns.spouse" :useFileHeaders="useFileHeaders"
                            :memberType="memberTypes[1]" :selectedColumns="selectedColumns" :mappingList="mappingArrayNaming" />
                    </v-col>
                    </v-row>
                </div> 

            <!-- 
                Adult Dependents
            -->            
                <div class="mx-auto pb-10">

                    <h3 class="mb-3">Adult Dependents</h3>
                    <v-col class="d-flex justify-center justify-md-end px-0">
                    <v-btn
                        color="primary"
                        v-on:click="handleAddAdult"
                        id="adult-add"
                        > Add Adult Dependent</v-btn>
                    </v-col>

                    <div 
                        v-for="(dependent, index) in flatFileColumns.dependent"
                        :key="index"
                    >
                        <h3 class="mb-3">
                            Adult {{ index + 1 }}
                            <span >
                                <v-icon v-on:click="handleAdultRemove(index)">
                                    mdi-close-thick
                                </v-icon>
                            </span>
                        </h3>
                        <DependentMapping :index="index" :activeColumns="activeColumns" :member="dependent" :useFileHeaders="useFileHeaders"
                            :memberType="memberTypes[2]" :mappingList="mappingArrayNaming" :selectedColumns="selectedColumns" />

                    </div>
                </div>    

            <!-- 
                Children (Minor Children)
            -->            
                <div class="mx-auto pb-10">

                    <h3 class="mb-3">Minor Dependents (Children)</h3>

                    <v-col class="d-flex justify-center justify-md-end px-0">
                    <v-btn
                        color="primary"
                        v-on:click="handleAddChild"
                        id="child-add"
                        >Add Child</v-btn>
                    </v-col>

                    <div 
                        v-for="(dependent, index) in flatFileColumns.child"
                        :key="index"
                    >
                        <h3 class="mb-3">
                            Child {{ index + 1 }}
                            <span >
                                <v-icon v-on:click="handleChildRemove(index)">
                                    mdi-close-thick
                                </v-icon>
                            </span>
                        </h3>
                        <DependentMapping :index="index" :activeColumns="activeColumns" :member="dependent" :useFileHeaders="useFileHeaders"
                            :memberType="memberTypes[3]" :mappingList="mappingArrayNaming" :selectedColumns="selectedColumns" />

                    </div>
                </div>        
            </div>


        <!-- 
            Mapping Primary As Relationship File views
        -->   
        <div v-if="showPrimaryAsRelation">
         
         <div class="mx-auto pb-10 py-10">
            
            <h3 class="mb-3">Formats</h3>   
            <v-row>                      
                <!-- Date Format -->
                <v-col sm="6" >
                    <v-row>
                        <v-col v-if="primaryAsRelationshipColumns.dateFormat != ''">
                            <v-row>
                                <v-col sm="11">
                                    <v-select
                                        class="pt-1 pl-sm-6"
                                        v-model.trim="primaryAsRelationshipColumns.dateFormat" 
                                        :items="dateTimeFormats"
                                        item-text="name"
                                        item-value="valueType"
                                        label="Date Format"
                                        :disabled="true"
                                        >
                                    </v-select>
                                </v-col>
                                <v-col sm="1" class="mt-3">
                                    <v-icon v-on:click="primaryAsRelationshipColumns.dateFormat = ''">
                                        mdi-close
                                    </v-icon>
                                </v-col>
                                </v-row>
                        </v-col>     
                        <v-col sm="12" v-if="primaryAsRelationshipColumns.dateFormat == ''">
                            <span class="d-flex">
                                <v-select
                                    id="employeeDateFormat-text"
                                    ref="employeeDateFormat"
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="primaryAsRelationshipColumns.dateFormat" 
                                    :items="dateTimeFormats"
                                    item-text="name"
                                    item-value="valueType"
                                    label="Date Format"
                                    >
                                </v-select>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Gender Format -->
                <v-col sm="6" >
                    <v-row>
                        <v-col v-if="primaryAsRelationshipColumns.genderFormat">
                            <v-row>
                                <v-col sm="11">
                                    <v-select
                                        class="pt-1 pl-sm-6"
                                        v-model.trim="primaryAsRelationshipColumns.genderFormat" 
                                        :items="genderFormats"
                                        item-text="name"
                                        item-value="valueType"
                                        label="Gender Format"
                                        :disabled="true"
                                        >
                                    </v-select>
                                </v-col>
                                <v-col sm="1" class="mt-3">
                                    <v-icon v-on:click="primaryAsRelationshipColumns.genderFormat = ''">
                                        mdi-close
                                    </v-icon>
                                </v-col>
                                </v-row>
                        </v-col>     
                        <v-col sm="12" v-if="!primaryAsRelationshipColumns.genderFormat">
                            <span class="d-flex">
                                <v-select
                                    id="employeeGenderFormat-text"
                                    ref="employeeGenderFormatt"
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="primaryAsRelationshipColumns.genderFormat" 
                                    :items="genderFormats"
                                    item-text="name"
                                    item-value="valueType"
                                    label="Gender Format"
                                    >
                                </v-select>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- File Delimiter Format -->
                <v-col sm="6" >
                    <v-row>
                        <v-col v-if="primaryAsRelationshipColumns.fileDelimiterFormat">
                            <v-row>
                                <v-col sm="11">
                                    <v-select
                                        class="pt-1 pl-sm-6"
                                        v-model.trim="primaryAsRelationshipColumns.fileDelimiterFormat" 
                                        :items="fileDelimiterFormats"
                                        item-text="name"
                                        item-value="valueType"
                                        label="File Delimiter Format"
                                        :disabled="true"
                                        >
                                    </v-select>
                                </v-col>
                                <v-col sm="1" class="mt-3">
                                    <v-icon v-on:click="primaryAsRelationshipColumns.fileDelimiterFormat = ''">
                                        mdi-close
                                    </v-icon>
                                </v-col>
                                </v-row>
                        </v-col>     
                        <v-col sm="12" v-if="!primaryAsRelationshipColumns.fileDelimiterFormat">
                            <span class="d-flex">
                                <v-select
                                    id="employeeFileDelimiterFormat-text"
                                    ref="employeeFileDelimiterFormat"
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="primaryAsRelationshipColumns.fileDelimiterFormat" 
                                    :items="fileDelimiterFormats"
                                    item-text="name"
                                    item-value="valueType"
                                    label="File Delimiter Format"
                                    >
                                </v-select>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Member type Format -->
                <v-col sm="6" >
                    <v-row>
                        <v-col v-if="primaryAsRelationshipColumns.memberTypeFormat">
                            <v-row>
                                <v-col sm="11">
                                    <v-select
                                        class="pt-1 pl-sm-6"
                                        v-model.trim="primaryAsRelationshipColumns.memberTypeFormat" 
                                        :items="memberTypeFormats"
                                        item-text="name"
                                        item-value="valueType"
                                        label="Member Type Format"
                                        :disabled="true"
                                        >
                                    </v-select>
                                </v-col>
                                <v-col sm="1" class="mt-3">
                                    <v-icon v-on:click="primaryAsRelationshipColumns.memberTypeFormat = ''">
                                        mdi-close
                                    </v-icon>
                                </v-col>
                                </v-row>
                        </v-col>     
                        <v-col sm="12" v-if="!primaryAsRelationshipColumns.memberTypeFormat">
                            <span class="d-flex">
                                <v-select
                                    id="employeememberTypeFormat-text"
                                    ref="employeeMemberTypeFormat"
                                    class="pt-1 pl-sm-6"
                                    v-model.trim="primaryAsRelationshipColumns.memberTypeFormat" 
                                    :items="memberTypeFormats"
                                    item-text="name"
                                    item-value="valueType"
                                    label="Member Type Format"
                                    >
                                </v-select>
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
                
            </v-row>

             <h3 class="mb-3  py-6">Primary As Relationship</h3>   
             <v-row>
            <!-- 
                primary as relationship
            -->            
                <div class="mx-auto pb-10 py-5">
                        <PrimaryAsRelationshipMapping :activeColumns="activeColumns" :useFileHeaders="useFileHeaders"
                            :member="primaryAsRelationshipColumns" :mappingList="mappingArrayNaming" :selectedColumns="selectedColumns" />
                    </div> 
             </v-row>
        </div>
        </div>       
            
        <!-- 
            Actions
        --> 
            <v-row v-if="uploadComplete">
                <v-col cols="12">
                    <span class="primary--text subtitle-1">Template Test Results</span> 
                </v-col>   
                <v-col cols="12">
                    <v-data-table
                        :items="tableResult"
                        :headers="activeHeader"
                    ></v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="d-flex justify-end">
                    <v-btn v-if="!isNewTemplate" color="info" class="white--text ma-2" @click="handleNewTemplate" >Upload New Template</v-btn>
                    <v-btn v-if="uploadComplete" color="accept" class="white--text ma-2" @click="save" >Save Template</v-btn>
                    <v-btn color="cancel" class="white--text ma-2" @click="close" >Close Template</v-btn>
                </v-col>
            </v-row>

        </v-form>
        </v-card-text>        
      </v-card>

</template>
<script>
import { useEmployerStore } from "../../stores/employer";
import { ftpDateFormats } from "../../commons/date";
import { ftpGenderFormats } from "../../commons/member";
import { ftpFileDelimeterFormats } from "../../commons/member";
import { ftpMemberTypeFormats } from "../../commons/member";

export default {
    name: "UploadSettings",
    components: {
        DependentMapping: () => import('./FileMapping/DependentMapping.vue'),
        EmployeeMapping: () => import('./FileMapping/EmployeeMapping.vue'),
        PrimaryAsRelationshipMapping: () => import('./FileMapping/PrimaryAsRelationshipMapping.vue')
    },
    setup() {
        const employerStore = useEmployerStore();
        return { employerStore };
    },
    data(){
        return {
            loadingCompleted: false,
            mappingFile: null,
            fileResults: null,
            overlay: false,
            isFormValid: false,
            allFormErrors: [],
            htmlErrors: '',
            mappingType: 1,
            isNewTemplate: false,
            useFileHeaders: true,
            currentEmployerID: '',
            mappingArrayNaming: [],
            allColumns:[],
            activeColumns: [],
            uploadComplete: false,
            memberTypes: ['primary', 'spouse', 'adult', 'child'],
            dateTimeFormats: [...ftpDateFormats],
            genderFormats: [...ftpGenderFormats],
            fileDelimiterFormats: [...ftpFileDelimeterFormats],
            memberTypeFormats: [...ftpMemberTypeFormats],
            userInformation: {
                firstName:"",
                lastName:"",
                dateOfBirth:"",
                gender:"",
                email:"",
                phoneNumber:"",
                language:"",
                externalID: ""
            },
            primaryAsRelationshipColumns: {
                product:"",
                productEnd:"",
                productStart:"",
                firstName:"",
                lastName:"",
                dateOfBirth:"",
                gender:"",
                email:"",
                phoneNumber:"",
                address1:"",
                address2:"",
                city:"",
                state:"",
                zipCode:"",
                language:"",
                memberType:"",
                parentID:"",
                externalID: "",
                dateFormat: "",
                genderFormat: "",
                fileDelimiterFormat: "",
                memberTypeFormat: "",
                employerName: ""
            },
            flatFileColumns: {
                dateFormat: "",
                genderFormat: "",
                fileDelimiterFormat: "",
                memberTypeFormat: "",
                employer: {
                    name:"",
                    contact:""
                },
                product:{
                    product: "",
                    productStart: "",
                    productEnd: ""
                },
                employee:{
                    firstName:"",
                    lastName:"",
                    dateOfBirth:"",
                    gender:"",
                    email:"",
                    phoneNumber:"",
                    address1:"",
                    address2:"",
                    city:"",
                    state:"",
                    zipCode:"",
                    language:"",
                    externalID: ""
                },
                spouse: {},
                dependent:[
                ],
                child: [
                ]
            }
        }
    },
    beforeMount() {
        let currentEmployer = this.$route.query;
        if(currentEmployer != null && currentEmployer.employerID != null && currentEmployer.employerID != "")
        {
            this.currentEmployerID = currentEmployer.employerID;
        }
        else{
            this.$swal({
                    title: "Please provider employer ID",
                    icon: "warning",
                    confirmButtonColor: this.$vuetify.theme.themes.light.success,
                    confirmButtonText: "Ok",
                    buttons: true,
                });            
        }
        this.getPreviousMapping();
    },
    computed: {
        showSpouse:{
            get(){
                return this.flatFileColumns.spouse ? true: false;
            }

        },
        showFlat:{
            get(){
                return ((this.mappingType == 1) && (this.uploadComplete));
            }
        },
        showPrimaryAsRelation:{
            get(){
                return ((this.mappingType == 2) && (this.uploadComplete));
            }
        },
        selectedColumns:{
            get(){
                let result = [];
                this.allColumns.forEach( (x) => {
                    let selectedColumn = this.activeColumns.filter((item) => {
                        return item.columnName == x;
                    });

                    if(selectedColumn.length > 0){
                        result.push(selectedColumn[0].columnName);
                    }
                });

                return result;
            }    
        },
        activeHeader:{
            get(){
                let result = [];
                this.allColumns.forEach( (x) => {
                    if(! this.mappingArrayNaming.includes(x)){
                        let selectedColumn = this.activeColumns.filter((item) => {
                            return item.columnName == x;
                        });

                        if (selectedColumn.length > 0) {
                            result.push({
                                text: selectedColumn[0].mapping,
                                value: selectedColumn[0].columnName
                            });
                        }
                    }
                });
                return result;
            }
        },
        tableResult: {
            get(){
                let returnArray = [];
                if (this.fileResults && this.fileResults.data && this.fileResults.data.length > 0)
                {
                    this.fileResults.data.forEach((x) => {
                        let newEntry = {};
                        if(this.useFileHeaders){
                            this.activeHeader.forEach((item) => {
                                newEntry[item.value] = x[item.value];
                            })
                        }
                        else{
                            this.activeHeader.forEach((item) => {
                                let index = item.value.replace(/^\D+/g, '');                                
                                newEntry[item.value] = x[index - 1];
                            })
                        }
                        
                        returnArray.push(newEntry);
                    })
                }
                
                return returnArray;
            }
        }
    },
    methods: {
        handleAddAdult(){
            if(this.flatFileColumns.dependent.length < 2){
                this.flatFileColumns.dependent.push({ ...this.userInformation });
            }
            else {
                this.$swal({
                    title: "You can't add more than 2 adult dependents",
                    icon: "warning",
                    confirmButtonColor: this.$vuetify.theme.themes.light.success,
                    denyButtonColor: this.$vuetify.theme.themes.light.deny,
                    confirmButtonText: "Ok",
                    buttons: true,
                    });
            }
        },

        handleAddChild(){
            if(this.flatFileColumns.child.length < 5){
                this.flatFileColumns.child.push({ ...this.userInformation });
            }
            else {
                this.$swal({
                    title: "You can't add more than 5 minor dependents",
                    icon: "warning",
                    confirmButtonColor: this.$vuetify.theme.themes.light.success,
                    denyButtonColor: this.$vuetify.theme.themes.light.deny,
                    confirmButtonText: "Ok",
                    buttons: true,
                    });
            }            
        },

        handleAddSpouse(){
            if(this.flatFileColumns.spouse.firstName == null)
            {
                this.flatFileColumns.spouse = {
                    firstName:"",
                    lastName:"",
                    dateOfBirth:"",
                    gender:"",
                    email:"",
                    phoneNumber:"",
                    language:"",
                    externalID: ""
                }
            }           
        },
        handleSelect(event, mapping) {
            this.mappingArrayNaming.splice(this.mappingArrayNaming.indexOf(event), 1);
            let selectedColumn = this.activeColumns.filter((item) => {
                                    return item.columnName == event;
                                });

            if(selectedColumn.length == 0){
                this.activeColumns.push({
                    columnName: event,
                    mapping: mapping
                });
            }
        },
        handleClearSelection: function(value){
            let mapItem = this.mappingArrayNaming.find(x => x == value);
            if(!mapItem){
                this.mappingArrayNaming.push(value);
            }

            this.activeColumns.splice(this.activeColumns.findIndex(x => x.columnName == value), 1);

        },
        handleAdultRemove(index){
            this.handleDependentContentRemove(this.flatFileColumns.dependent[index]);
            this.flatFileColumns.dependent.splice(index, 1);
        },

        handleChildRemove(index){
            this.handleDependentContentRemove(this.flatFileColumns.child[index]);
            this.flatFileColumns.child.splice(index, 1);
        },
        handleSpouseRemove(){
            this.handleDependentContentRemove(this.flatFileColumns.spouse);
            this.flatFileColumns.spouse = {};
        },
        handleDependentContentRemove(dependent){
            if(dependent != null){
                if(dependent.firstName != null && dependent.firstName != ""){
                    let mapItem = this.mappingArrayNaming.find(x => x == dependent.firstName);
                    if(!mapItem){
                        this.mappingArrayNaming.push(dependent.firstName);
                    }
                    if(this.activeColumns.findIndex(x => x.columnName == dependent.firstName) >= 0)
                    {
                        this.activeColumns.splice(this.activeColumns.findIndex(x => x.columnName == dependent.firstName), 1);
                    }
                }
                if(dependent.lastName != null && dependent.lastName != ""){
                    let mapItem = this.mappingArrayNaming.find(x => x == dependent.lastName);
                    if(!mapItem){
                        this.mappingArrayNaming.push(dependent.lastName);
                    }
                    if(this.activeColumns.findIndex(x => x.columnName == dependent.lastName) >= 0)
                    {
                        this.activeColumns.splice(this.activeColumns.findIndex(x => x.columnName == dependent.lastName), 1);
                    }
                }
                if(dependent.dateOfBirth != null && dependent.dateOfBirth != ""){
                    let mapItem = this.mappingArrayNaming.find(x => x == dependent.dateOfBirth);
                    if(!mapItem){
                        this.mappingArrayNaming.push(dependent.dateOfBirth);
                    }
                    if(this.activeColumns.findIndex(x => x.columnName == dependent.dateOfBirth) >= 0)
                    {
                        this.activeColumns.splice(this.activeColumns.findIndex(x => x.columnName == dependent.dateOfBirth), 1);
                    }
                }
                if(dependent.email != null && dependent.email != ""){
                    let mapItem = this.mappingArrayNaming.find(x => x == dependent.email);
                    if(!mapItem){
                        this.mappingArrayNaming.push(dependent.email);
                    }
                    if(this.activeColumns.findIndex(x => x.columnName == dependent.email) >= 0)
                    {
                        this.activeColumns.splice(this.activeColumns.findIndex(x => x.columnName == dependent.email), 1);
                    }
                }
                if(dependent.phoneNumber != null && dependent.phoneNumber != ""){
                    let mapItem = this.mappingArrayNaming.find(x => x == dependent.phoneNumber);
                    if(!mapItem){
                        this.mappingArrayNaming.push(dependent.phoneNumber);
                    }
                    if(this.activeColumns.findIndex(x => x.columnName == dependent.phoneNumber) >= 0)
                    {
                        this.activeColumns.splice(this.activeColumns.findIndex(x => x.columnName == dependent.phoneNumber), 1);
                    }
                }
                if(dependent.gender != null && dependent.gender != ""){
                    let mapItem = this.mappingArrayNaming.find(x => x == dependent.gender);
                    if(!mapItem){
                        this.mappingArrayNaming.push(dependent.gender);
                    }
                    if(this.activeColumns.findIndex(x => x.columnName == dependent.gender) >= 0)
                    {
                        this.activeColumns.splice(this.activeColumns.findIndex(x => x.columnName == dependent.gender), 1);
                    }
                }
                if(dependent.language != null && dependent.language != ""){
                    let mapItem = this.mappingArrayNaming.find(x => x == dependent.language);
                    if(!mapItem){
                        this.mappingArrayNaming.push(dependent.language);
                    }
                    if(this.activeColumns.findIndex(x => x.columnName == dependent.language) >= 0)
                    {
                        this.activeColumns.splice(this.activeColumns.findIndex(x => x.columnName == dependent.language), 1);
                    }
                }              
            }
        },
        close(){
            this.$emit('cancel');
        },
        async save(){
            this.handleValidationBeforeSaving();
            if(!this.$refs.formUpload.validate())
            {
                this.$swal({
                    title: "Enter required fields",
                    icon: "warning",
                    html: this.htmlErrors,
                    confirmButtonColor: this.$vuetify.theme.themes.light.warning,
                    confirmButtonText: "OK",
                    buttons: true,
                }).then(() => {
                    this.htmlErrors = "";
                    this.allFormErrors = [];
                });
            }
            else{
                if(this.currentEmployerID == null || this.currentEmployerID == ''){
                    this.$swal({
                                title: "Employer could not be identified. Please try again or contact customer support.",
                                icon: "warning",
                                confirmButtonColor: this.$vuetify.theme.themes.light.warning,
                                confirmButtonText: "OK",
                                buttons: true,
                        });
                    return;
                }
                if(this.mappingType == 1)
                {
                    let uploadRequest = {
                        mapping: JSON.stringify(this.flatFileColumns),
                        employerID: this.currentEmployerID,
                        mappingColumns: JSON.stringify(this.activeColumns),
                        allMappingColumns: JSON.stringify(this.allColumns),
                        mappingOptions: {
                            useFileHeaders: this.useFileHeaders,
                            mappingType: this.mappingType
                        }
                    };

                    await this.employerStore
                        .saveEmployerUploadSettings(uploadRequest)
                        .then((res) => {
                            if(res){
                                this.$swal({
                                    title: "Successful",
                                    icon: "Info",
                                    confirmButtonColor: this.$vuetify.theme.themes.light.sucess,
                                    confirmButtonText: "OK",
                                    buttons: true,
                                }).then(()=> {
                                    this.close();
                                });
                            }
                        });
                }
                else if (this.mappingType == 2)
                {
                    let uploadRequest = {
                        mapping: JSON.stringify(this.primaryAsRelationshipColumns),
                        employerID: this.currentEmployerID,
                        mappingColumns: JSON.stringify(this.activeColumns),
                        allMappingColumns: JSON.stringify(this.allColumns),
                        mappingOptions: {
                            useFileHeaders: this.useFileHeaders,
                            mappingType: this.mappingType
                        }
                    };

                    await this.employerStore
                        .saveEmployerUploadSettings(uploadRequest)
                        .then((res) => {
                            if(res){
                                this.$swal({
                                    title: "Successful",
                                    icon: "Info",
                                    confirmButtonColor: this.$vuetify.theme.themes.light.sucess,
                                    confirmButtonText: "OK",
                                    buttons: true,
                                }).then(()=> {
                                    this.close()
                                });
                            }
                        });
                }
            }
        },

        handleValidationBeforeSaving(){
            //form validation
            if(this.showFlat){
                let errorsObject = {
                    name: "Employee",
                    errors: [],
                };

                //validate employee
                this.validateUserInformation(this.flatFileColumns.employee, errorsObject, this.memberTypes[0]);
                this.allFormErrors.push(errorsObject);

                //validate spouse
                if(this.flatFileColumns.spouse.firstName != null){
                    errorsObject = {
                        name: "Spouse",
                        errors: [],
                    };
                    this.validateUserInformation(this.flatFileColumns.spouse, errorsObject, this.memberTypes[1]);
                    this.allFormErrors.push(errorsObject);
                }

                //validate adult
                this.flatFileColumns.dependent.filter((item, idx) => {
                    errorsObject = {
                        name: "Adult " + (idx + 1),
                        errors: [],
                    };
                    this.validateUserInformation(item, errorsObject, this.memberTypes[2]);
                    this.allFormErrors.push(errorsObject);
                });

                //validate children
                this.flatFileColumns.child.filter((item, idx) => {
                    errorsObject = {
                        name: "Child " + (idx + 1),
                        errors: [],
                    };
                    this.validateUserInformation(item, errorsObject, this.memberTypes[3]);
                    this.allFormErrors.push(errorsObject);
                });
            }
            else if(this.showPrimaryAsRelation){
                //validate primary as relation
                let errorsObject = {
                    name: "Primary As Relation",
                    errors: [],
                };

                this.validateUserInformation(this.primaryAsRelationshipColumns, errorsObject, this.memberTypes[0]);

                if(this.primaryAsRelationshipColumns.memberType == ""){
                    errorsObject.errors.push(`Member Type is required`);
                }
                if(this.primaryAsRelationshipColumns.parentID == ""){
                    errorsObject.errors.push(`Parent ID is required`);
                }
                this.allFormErrors.push(errorsObject);
            }

            //map errors to html
            this.allFormErrors.map((itemsForVueForm, idx) => {
                if (itemsForVueForm.errors.length) {
                    if (idx == 0) {
                        this.htmlErrors += `<h2>${itemsForVueForm.name}</h2>`;
                    } else {
                        this.htmlErrors += `<hr style='color:#E0E0E0'/><h2>${itemsForVueForm.name}</h2>`;
                    }
                    itemsForVueForm.errors.map((item) => {
                        this.htmlErrors += `<p>${item}</p>`;
                    });
                }
            });
        },

        validateUserInformation(member, errors, memberType){
            if(member.firstName == ""){
                errors.errors.push(`First Name is required`);
            }
            if(member.lastName == ""){
                errors.errors.push(`Last Name is required`);
            }
            if(member.dateOfBirth == ""){
                errors.errors.push(`Date of Birth is required`);
            }            
            if(memberType != this.memberTypes[3]){
                if(member.email == ""){
                    errors.errors.push(`Email is required`);
                }
            }
        },

        async getPreviousMapping(){
            await this.employerStore
                .GetEmployerUploadSettings(this.currentEmployerID)
                .then((res) => {
                    if(res){
                        this.handlePostProcess(res);
                    }
                }).catch((err) => {
                    console.error("Error: ", err);
                    this.handleNewTemplate();
                });
        },

        handlePostProcess(res){
            this.activeColumns = JSON.parse(res.data.mappingColumns);
            this.uploadComplete = true;
            this.mappingType = (JSON.parse(res.data.mappingOptions)).mappingType;
            this.useFileHeaders = (JSON.parse(res.data.mappingOptions)).useFileHeaders;
            this.allColumns = (JSON.parse(res.data.allMappingColumns));
            this.isNewTemplate = false;
            this.loadingCompleted = true;

            if(this.mappingType == 1){
                this.flatFileColumns = JSON.parse(res.data.mapping);
                if(!this.flatFileColumns.dateFormat)
                    this.flatFileColumns.dateFormat = "";
                if(!this.flatFileColumns.genderFormat)
                    this.flatFileColumns.genderFormat = "";
                if(!this.flatFileColumns.fileDelimiterFormat)
                    this.flatFileColumns.fileDelimiterFormat = "";
                if(!this.flatFileColumns.memberTypeFormat)
                    this.flatFileColumns.memberTypeFormat = "";
            }
            else if(this.mappingType == 2){
                this.primaryAsRelationshipColumns = JSON.parse(res.data.mapping);
                if(!this.primaryAsRelationshipColumns.dateFormat)
                    this.primaryAsRelationshipColumns.dateFormat = "";
                if(!this.primaryAsRelationshipColumns.genderFormat)
                    this.primaryAsRelationshipColumns.genderFormat = "";
                if(!this.primaryAsRelationshipColumns.fileDelimiterFormat)
                    this.primaryAsRelationshipColumns.fileDelimiterFormat = "";
                if(!this.primaryAsRelationshipColumns.memberTypeFormat)
                    this.primaryAsRelationshipColumns.memberTypeFormat = "";
                if(!this.primaryAsRelationshipColumns.employerName)
                    this.primaryAsRelationshipColumns.employerName = "";
            }

            let selectedItems = [];
            this.allColumns.forEach( (x) => {
                let selectedColumn = this.activeColumns.filter((item) => {
                        return item.columnName == x;
                    });
                
                if(selectedColumn.length == 0){
                    selectedItems.push(x);
                }
                });
                
            this.mappingArrayNaming = [...selectedItems];

        },

        previewFile() {
            this.$papa.parse(this.mappingFile, {
                header: this.useFileHeaders,
                skipEmptyLines: true,
                complete: (results) => {
                    let item = (JSON.stringify(results)).replace(/"\s*/g, '"');
                    this.fileResults = JSON.parse(item);
                    this.compileResults() }
            });
        },

        handleNewTemplate(){
            this.uploadComplete = false;
            this.mappingType = 1,
            this.useFileHeaders = true;
            this.loadingCompleted = true;
            this.mappingArrayNaming = [];
            this.activeColumns = [];
            this.primaryAsRelationshipColumns = {
                product:"",
                productEnd:"",
                productStart:"",
                firstName:"",
                lastName:"",
                dateOfBirth:"",
                gender:"",
                email:"",
                phoneNumber:"",
                address1:"",
                address2:"",
                city:"",
                state:"",
                zipCode:"",
                language:"",
                memberType:"",
                parentID:"",
                externalID: "",
                dateFormat:"",
                genderFormat: "",
                fileDelimiterFormat: "",
                memberTypeFormat: "",
                employerName: ""
            };
            this.flatFileColumns = {
                dateFormat:"",
                genderFormat: "",
                fileDelimiterFormat: "",
                memberTypeFormat: "",
                employer: {
                    name:"",
                    contact:""
                },
                product:{
                    product: "",
                    productStart: "",
                    productEnd: ""
                },
                employee:{
                    firstName:"",
                    lastName:"",
                    dateOfBirth:"",
                    gender:"",
                    email:"",
                    phoneNumber:"",
                    address1:"",
                    address2:"",
                    city:"",
                    state:"",
                    zipCode:"",
                    language:"",
                    externalID: ""
                },
                spouse: {},
                dependent:[
                ],
                child: [
                ]
            };
            this.isNewTemplate = true;
        },
        
        compileResults(){
            let newArray = [];
            let tempMapping = [];
            if (this.useFileHeaders)
            {
                this.fileResults.meta.fields.map((item, ind) => {
                    newArray.push({
                        index: ind,
                        columnName: item,
                        columnValue: '',
                    });
                })
                tempMapping = [...newArray];
            }
            else
            {
                this.fileResults.data[0].map((item, ind) => {
                    newArray.push({
                        columnName: 'Position '+ (ind + 1),
                        columnValue: '',
                        index: ind,
                    });
                })
                tempMapping = [...newArray];
            }

            let newMappingValues = [];
            tempMapping.forEach((item) => {
                newMappingValues.push(
                    item['columnName'].trim()
                );
            });

            //Check for duplicate headers
            let hasDuplicateHeader = false;
            let duplicateHeaderName = [];
            newMappingValues.forEach((item, ind1) => {
                newMappingValues.forEach((subItem, ind2) => {
                    if(ind1 != ind2){
                        if(item.toLowerCase().trim() == subItem.toLowerCase().trim()){
                            hasDuplicateHeader = true;
                            let existingDuplicate = duplicateHeaderName.filter((ex) => {
                                return item == ex;
                            });
                            if(existingDuplicate.length == 0){
                                duplicateHeaderName.push(item);
                            }
                        }
                    }
                });
            });

            if(hasDuplicateHeader){
                let htmlError = '';
                htmlError += `<h4>Duplicate headers</h4> <hr/>`;
                duplicateHeaderName.forEach( (item) => {
                    htmlError += `<p>${item}</p>`;
                });

                let message = "<hr/>Please fix the file headers within your file or toggle off the 'Use File Headers' option";
                htmlError += message;

                this.$swal  ({
                    title: "Duplicate header detected",
                    html: htmlError,
                    icon: "warning",
                    confirmButtonColor: this.$vuetify.theme.themes.light.warning,
                    confirmButtonText: "OK",
                    buttons: true,
                });                
            }
            else{
                this.mappingArrayNaming = [...newMappingValues];
                this.allColumns = [...newMappingValues];
                this.uploadComplete = true;
                this.isNewTemplate = false;
            }
        }
    }
}
</script>
<style>
.tableRow .itemRow:nth-child(odd) {
    background-color: rgb(191, 214, 255) ;
}
</style>