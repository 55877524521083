<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-alert border="left" dismissible type="warning" v-if="warning">
          Your {{employerStore.fieldLabelSettings.groupLabel}} has not yet had billing information added, please complete this to begin adding {{employerStore.fieldLabelSettings.secondarySubscriberLabel}}
        </v-alert>
        
        <v-row> 
          <v-col cols="12">
            <!-- start overlay -->
            <v-overlay :value="overlay">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <!-- end overlay -->

            <!-- paymentMethods  -->
              <v-card elevation="0" class="mb-8">
                
                <v-card-title>
                  <span class="text-h6 font-weight-bold primary--text">Saved Payment Method</span>
                </v-card-title>
        
                <v-list three-line>
                  <v-list-item>
                    <v-list-item-content>
                      <div class="d-flex justify-space-between flex-wrap">
                        <span class="primary--text">Payment Methods</span>
                        <div>
                          <v-btn depressed color="success" @click="toggleForm()">
                           {{(ObjectExist(subscriptionData) && subscriptionData.subscription.payment_type && subscriptionData.subscription.payment_type) ? 'Update' : 'Add'}}
                           </v-btn>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
      
                  <v-divider></v-divider>
      
                  <v-list-item v-if="ObjectExist(subscriptionData)">
      
                      <v-list-item-avatar color="white">
                        <v-icon class="black" dark v-if="subscriptionData.subscription.payment_type != null && subscriptionData.subscription.payment_type == 'credit_card'">
                          mdi-credit-card-check-outline
                        </v-icon>
                        <v-icon class="black" dark v-if="subscriptionData.subscription.payment_type != null && subscriptionData.subscription.payment_type == 'bank_account'">
                          mdi-bank-check
                        </v-icon>
                      </v-list-item-avatar>
                      
                      <v-list-item-content v-if="subscriptionData.subscription.payment_type != null && subscriptionData.subscription.payment_type == 'credit_card'">
                        <v-list-item-title>
                          <strong>{{subscriptionData.subscription.credit_card.masked_card_number}} | </strong> 
                          <span>{{ subscriptionData.subscription.currency }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="cancel--text fs-16 text-uppercase">{{subscriptionData.subscription.customer.first_name}} {{subscriptionData.subscription.customer.last_name}}</span>
                          <br>
                          <span>
                            Expiration date: {{subscriptionData.subscription.credit_card.expiration_month}} / {{subscriptionData.subscription.credit_card.expiration_year}}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
      
                      <!-- for ach -->
                      <v-list-item-content v-if="subscriptionData.subscription.payment_type != null && subscriptionData.subscription.payment_type == 'bank_account'">
                        <v-list-item-title>
                          <strong>{{subscriptionData.subscription.bank_account.bank_name}} | </strong> 
                          <span>{{ subscriptionData.subscription.currency }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="cancel--text">Account Number</span>
                          <br>
                          <span class="fs-16">{{ subscriptionData.subscription.bank_account.masked_bank_account_number }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
      
                  </v-list-item>
                  <v-list-item v-else>
                    <v-list-item-content class="text-center danger--text">
                      <span>No Payment Method, click on the Add Button</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
      
              </v-card>
      
            <!-- chargify -->
              <v-row v-if="displayChargify">
                <v-col cols="12">
                  <v-select
                    v-model.trim="paymentType" :items="paymentMethods" :item-text="(item) => item.name" class="mb-md-8"
                    :item-value="(item) => item.value" label="Select Payment Type" @change="changeItem()" clearable required></v-select>
          
                  <v-form v-model="valid" ref="form" lazy-validation>
                    <div id="chargify-form">
                      <!-- start collection of first and last name, and email -->
                      <v-row class="mb-md-n8">
                        <v-col md="6" cols="12">
                          <v-text-field :label="paymentType != 'card' ? 'Account First Name' : 'First Name on Card'" 
                            v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-'. ]/ } } }" 
                            :rules="[ (v) => !!v || (paymentType == 'card' ? 'First Name on Card is required' : 'Account First Name is required') ]"
                            data-chargify="firstName" outlined v-model.trim="billingDetails.first_name"></v-text-field>
                        </v-col> 
                        <v-col md="6" cols="12">
                          <v-text-field :label="paymentType != 'card' ? 'Account Contact Last Name' : 'Last Name on Card'" 
                            v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-'. ]/ } } }" 
                            :rules="[ (v) => !!v || (paymentType == 'card' ? 'Last Name on Card is required' : 'Account Last Name is required') ]"
                            data-chargify="lastName" outlined v-model.trim="billingDetails.last_name"></v-text-field>
                        </v-col>
                        <v-col md="12" cols="12">
                          <v-text-field label="Email" :rules="[
                            (v) => !!v || 'E-mail is required',
                            rules.emailValidation,
                          ]" data-chargify="email" outlined v-model.trim="billingDetails.email"></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- end collection of first and last name, and email -->
                      
                      <div v-if="paymentType == 'card'">
                        <!-- start chargifyJs iframe for card info collection  -->
                        <v-row>
                          <v-col md="6" cols="12">
                            <div id="cc_number"></div>
                          </v-col>
                          <v-col md="2" cols="12">
                            <div id="cc_month"></div>
                          </v-col>
                          <v-col md="2" cols="12">
                            <div id="cc_year"></div>
                          </v-col>
                          <v-col md="2" cols="12">
                            <div id="cc_cvv"></div>
                          </v-col>
                        </v-row>
                        <!-- end chargifyJs iframe for card  -->
                      </div>
                      
                      <div v-else>
                        <!-- start chargifyJs iframe for bank account info collection -->
                        <v-row class="mb-md-n8">
                          <v-col md="6" cols="12">
                            <div id="ach_bank_name"></div>
                          </v-col>
                          <v-col md="6" cols="12">
                            <div id="ach_routing_number"></div>
                          </v-col>
                        </v-row>
                        <v-row class="mb-md-n8">
                          <v-col md="6" cols="12">
                            <div id="ach_account_number"></div>
                          </v-col>
                          <v-col md="6" cols="12">
                            <div id="ach_account_type"></div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="6" cols="12">
                            <div id="ach_account_holder_type"></div>
                          </v-col>
                        </v-row>
                        <!-- end chargifyJs iframe for bank account info collection -->
                      </div>

                      <div class="my-5">
                        <h4 class="primary--text">Billing Details</h4>
                      </div>
                    
                      <div>
                        <!-- start collection of billing details -->
                        <v-row>
                          <v-col md="6" cols="12">
                            <v-text-field label="Street 1" data-chargify="address" outlined v-model.trim="billingDetails.address" 
                            v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } } }"></v-text-field>
                          </v-col>
                          <v-col md="6" cols="12">
                            <v-text-field label="Street 2" data-chargify="address2" outlined v-model.trim="billingDetails.address_2"
                            v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z-.£,'&()/%0-9# ]/ } } }"></v-text-field>
                          </v-col>
                        </v-row>
              
                        <v-row>
                          <v-col md="6" cols="12">
                            <v-text-field label="Postal Code" data-chargify="zip" outlined v-model.trim="billingDetails.zip"
                            v-maska="{ mask: '#*', tokens: { '#': { pattern: /[0-9]/ } } }" :counter="5" 
                            :rules="[ (v) => (v && v.length === 5) || 'Postal Code must have 5 digits' ]">
                            </v-text-field>
                          </v-col>
                          <v-col md="6" cols="12">
                            <v-text-field label="City" data-chargify="city" outlined v-model.trim="billingDetails.city"
                            v-maska="{ mask: 'S*', tokens: { S: { pattern: /[a-zA-Z- ]/ } } }"></v-text-field>
                          </v-col>
                        </v-row>
  
                        <v-row>
                          <v-col md="6" cols="12">
                            <country-select v-model.trim="billingDetails.country" data-chargify="country" :country="billingDetails.country" :topCountry="'US'" :whiteList="['US', 'CA']" class="me-3" />
                          </v-col>
                          <v-col md="6" cols="12">
                            <region-select v-model.trim="billingDetails.state" data-chargify="state" :country="billingDetails.country" :region="billingDetails.state" />
                          </v-col>
                        </v-row>
                        <!-- end collection of billing details -->
                      </div>
                    </div>

                    <v-col cols="12" class="d-flex justify-center">
                      <!-- Form Submit -->
                      <v-btn @click.prevent="Save" color="success">Save</v-btn>
                      <v-btn @click="displayChargify = false" class="ml-3" color="cancel" dark>Cancel</v-btn>
                    </v-col>
                  </v-form>
          
                </v-col>
              </v-row>
          </v-col>
          
        </v-row>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { useAuthStore } from "../stores/auth";
import { useEmployerStore } from "../stores/employer";
import { convertToCustomerAttribute } from "../commons/employer";
import { useChargifyStore } from "../stores/chargify";
import { isValidEmail } from "../commons/formValidators";

export default {
  name: 'BillingComponent',
  setup() {
    const employerStore = useEmployerStore();
    const authStore = useAuthStore();
    const chargifyStore = useChargifyStore();

    return { authStore, employerStore, chargifyStore}
  },
  data() {
    return {
      // instantiation of chargifyJs
      chargify: new window.Chargify(),
      paymentType: 'bank',
      token: '',
      overlay: false,
      //payment method array of object
      paymentMethods: [
        { name: 'Credit Card', value: 'card' },
        { name: 'Bank Account', value: 'bank' }
      ],
      snackbar: false,
      vertical: true,
      errorText: '',
      valid: true,
      displayChargify: false,
      subscriptionData: {},
      employer: {},
      billingDetails: {
        email: "",
        first_name: "",
        last_name: "",
        address: "",
        address_2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
      warning: false,
      leavePage: false,
      rules: {
        emailValidation: [
          (emailAddress) => isValidEmail(emailAddress) || "E-mail must be valid"
        ],
      },
    }
  },

  methods: {
    //checks if the response from getEmployerSubscription object is ObjectExist or not
    ObjectExist: function(obj) {
      return Object.keys(obj).length === 1;
    },
    //toggles loadChargifyJs
    toggleForm: function() {
      this.loadChargifyJs();
      this.displayChargify = !this.displayChargify;
    },
    loadPage() {
      this.overlay = true;
      //get subscription
      this.chargifyStore.getEmployerSubscription(this.$route.query.employerID).then(res => {
        this.subscriptionData = res;
        this.overlay = false;
        if(this.ObjectExist(this.subscriptionData)) {
          //prefill billing address
          this.billingDetails = this.subscriptionData.subscription.customer;
          //prefill billing email
          // this.billingDetails.email = this.subscriptionData.subscription.customer.email;
          //prefill payment method
          this.paymentType = this.subscriptionData.subscription.payment_type && this.subscriptionData.subscription.payment_type == 'credit_card' ? 'card' : 'bank';
        }
        //get employer details
        this.employerStore.getEmployerByID(this.$route.query.employerID).then((res) => {
          this.employer = res;
          if(!this.ObjectExist(this.subscriptionData)) {
            //prefill billing address
            this.billingDetails.address = res.primaryStreet;
            this.billingDetails.address_2 = res.primaryStreetTwo;
            this.billingDetails.city = res.primaryCity;
            this.billingDetails.state = res.primaryRegion;
            this.billingDetails.zip = res.primaryPostal;
            this.billingDetails.country = res.primaryCountry;
          }
        });
      });
    },
    //invokes loadChargifyJs when a user selects a preferred payment method
    changeItem: function() {
      this.chargify.unload();

      this.loadChargifyJs();
    },
    Save() {
      // validate form
      if (!this.$refs.form.validate()) {
        return this.$swal({
          title: "Error",
          icon: "error",
          html: ("" + Object.keys(this.$refs.form._data.inputs).map((key) => {
              if (this.$refs.form._data.inputs[key].validations) {
                  if (this.$refs.form._data.inputs[key].validations.length > 0) {
                      return "<br>" + "- " + this.$refs.form._data.inputs[key].validations[0];
                  }
              }
          })).replace(/,/g, ""),
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Try Again",
        });
      }

      //toggle overlay to true
      this.overlay = true;

      this.chargify.token(document.querySelector('#chargify-form'),
        (token) => { this.token = token; },
        (error) => {
          this.overlay = false;
          
          if(error.status){
            this.errorText = error.errors;
            return this.$swal({
              title: "Error",
              icon: "error",
              text: this.errorText,
              confirmButtonColor: this.$vuetify.theme.themes.light.success,
              denyButtonColor: this.$vuetify.theme.themes.light.deny,
              confirmButtonText: "Try Again",
            });
          }
          else{
            this.errorText = error.message;
            if(error.invalidFields){
              let errorsToOuput = [];
              error.invalidFields.forEach(e => {
                  if(e == 'bankName')
                    errorsToOuput.push('Bank Name is required');
                  if(e == 'routingNumber')
                    errorsToOuput.push('Routing Number is required');
                  if(e == 'accountNumber')
                    errorsToOuput.push('Account Number is required');
                  if(e == 'accountType')
                    errorsToOuput.push('Account Type is required');
                  if(e == 'accountHolderType')
                    errorsToOuput.push('Account Holder Type is required');
                  if(e == 'number')
                    errorsToOuput.push('Card Number is required');
                  if(e == 'month')
                    errorsToOuput.push('Card Expiration Month is required');
                  if(e == 'year')
                    errorsToOuput.push('Card Expiration Year is required');
              })

              this.errorText = errorsToOuput;
            }

            return this.$swal({
              title: "Error",
              icon: "error",
              html: ("" + Object.keys(this.errorText).map((key) => {
                return "<br>" + "- " + this.errorText[key];
              })).replace(/,/g, ""),
              confirmButtonColor: this.$vuetify.theme.themes.light.success,
              denyButtonColor: this.$vuetify.theme.themes.light.deny,
              confirmButtonText: "Try Again",
            });
          }
          
        }
      );
    },
    //loads chargifyJs iframe
    loadChargifyJs: function() {
      let chargifyLoader = {
      publicKey: process.env.VUE_APP_CHARGIFY_PUBLIC_KEY,
      // form type (possible values: 'card' or 'bank')
      threeDSecure: true,
      type: this.paymentType || 'bank',
      serverHost: process.env.VUE_APP_CHARGIFY_SERVER_HOST,
      hideCardImage: false,
      optionalLabel: ' ',
      requiredLabel: '*',
      // addressDropdowns: true,
      //style for chargifyJs iframe
      style: {
          '#chargify-form': { border: '1px dashed #ffc0cb57' },
          '#cc_month': { width: '80%', 'min-height':'140px', 'min-width': '100%' },
          '#cc_number': { width: '100%', 'min-height':'140px', 'min-width': '100%' },
          '#cc_year': { width: '80%' , 'min-height':'140px', 'min-width': '100%' },
          '#cc_cvv': { width: '80%' , 'min-height':'140px', 'min-width': '100%' },
          '#ach_bank_name': { width: '100%' },
          '#ach_routing_number': { width: '100%' },
          '#ach_account_number': { width: '100%' },
          '#ach_account_type': { width: '100%' },
          '#ach_account_holder_type': { width: '100%' },
          '.cfy-input.cfy-input--number': { 'overflow-y': 'hidden !important' },
          '.cfy-input.cfy-input--month': { 'overflow-y': 'hidden !important' },
          '.cfy-input.cfy-input--year': { 'overflow-y': 'hidden !important' },
          '.cfy-input.cfy-input--cvv': { 'overflow-y': 'hidden !important' },
          input: { width: "100%" },
          field: { width: "100%" },
        },
      }
      // depending on employer's payment method preference, this tells ChargifyJs what iframed fields to display on the web form
      if(this.paymentType == 'card'){
        // start of fields for card payment method
        chargifyLoader.fields = {
          number: { selector: '#cc_number' },
          month: { selector: '#cc_month' },
          year: { selector: '#cc_year' },
          cvv: { selector: '#cc_cvv' },
        }
        // end of fields for card payment method
      }else{
        // start of fields for bank account payment method
        chargifyLoader.fields = {
          bankName: {
              selector: '#ach_bank_name',
              label: 'Bank Name',
              placeholder: 'My Bank',
              message: 'Invalid Bank Name',
              required: true,
          },
          routingNumber: {
              selector: '#ach_routing_number',
              label: 'Routing Number',
              placeholder: '123412341234',
              message: 'Invalid Routing Number',
              required: true,
          },
          accountNumber: {
              selector: '#ach_account_number',
              label: 'Account Number',
              placeholder: '123412341234',
              message: 'Invalid Account Number',
              required: true
          },
          accountType: {
              selector: '#ach_account_type',
              label: 'Account Type',
              placeholder: 'Select Account Type',
              required: true,
              message: 'Select a valid Account Type'
          },
          accountHolderType: {
              selector: '#ach_account_holder_type',
              label: 'Account Holder',
              placeholder: 'Select Account Holder Type',
              required: true,
              message: 'Select a valid Account Holder'
          }
        }
        // end of fields for bank account payment method
      }
      //invoke chargify's load method with chargifyLoader object as a parameter
      this.chargify.load(chargifyLoader);
    },
  },
  created() {
    //watches for a token response from chargify and handles form submission to the api
    this.$watch("token", (chargifyToken) => {
      if(chargifyToken) {
        let customer = convertToCustomerAttribute(this.billingDetails.first_name, this.billingDetails.last_name, this.billingDetails.email, 
        this.employer.secondaryPostal,  this.employer.phonePrimary, this.employer.secondaryRegion, this.employer.secondaryCountry ?? 'US', 
        this.employer.secondaryCity, this.employer.secondaryStreet, this.employer.secondaryStreetTwo, this.employer.name);
          
      if(!this.employer.billingID){
        //creates subscription if employer's billingInfoCompleted is set to false
          this.chargifyStore.createEmployerSubscription(customer, this.$route.query.employerID, this.token, this.paymentType)
          .then(() => {
            this.overlay = false;
            return this.$swal({
              title: "Saved",
              icon: "success",
              confirmButtonColor: this.$vuetify.theme.themes.light.success,
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                this.leavePage = true;
                this.displayChargify = false;
                this.loadPage();
                this.employerStore.refreshSettings();
              }
            });
          })
          .catch((err) => {
            this.overlay = false;
            return this.$swal({
              title: "Error",
              icon: "error",
              html: Object.keys(err.errors).map((key) => {
                return "<br>" + "- " + err.errors[key];
              }),
              confirmButtonColor: this.$vuetify.theme.themes.light.success,
              denyButtonColor: this.$vuetify.theme.themes.light.deny,
              confirmButtonText: "Try Again",
            });
          });
      }else {
        //updates subscription if employer's billingInfoCompleted is set to true
          this.chargifyStore.updateEmployerSubscription(customer, this.employer.employerID, this.token, this.paymentType)
          .then(() => {
            this.overlay = false;
            return this.$swal({
              title: "Saved",
              icon: "success",
              confirmButtonColor: this.$vuetify.theme.themes.light.success,
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                this.leavePage = true;
                this.displayChargify = false
                this.loadPage();
                this.employerStore.refreshSettings();
              }
            });
          })
          .catch((err) => {
            this.overlay = false;
            return this.$swal({
              title: "Error",
              icon: "error",
              html: Object.keys(err.errors).map((key) => {
                return "<br>" + "- " + err.errors[key];
              }),
              confirmButtonColor: this.$vuetify.theme.themes.light.success,
              denyButtonColor: this.$vuetify.theme.themes.light.deny,
              confirmButtonText: "Try Again",
            });
          });
        }
        
      }
    })
  },
  mounted() {
    this.warning = false;
    if (this.$route.query.warning && this.$route.query.warning == true) {
      this.warning = true;
    }
    //check for an employerID query parameter
    if (this.$route.query.employerID) {
      this.loadPage();
    } else {
    //throw an error and redirect user if the check for an employerID query parameter is false
      this.$swal({
        title: "Error",
        text: "Invalid Page",
        icon: "error",
        confirmButtonColor: this.$vuetify.theme.themes.light.success,
        denyButtonColor: this.$vuetify.theme.themes.light.deny,
        confirmButtonText: "Ok",
        showCancelButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then((okay) => {
        if (okay) {
          this.$router.push({ name: "group_admin_index" });
        }
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if(!this.leavePage && !this.ObjectExist(this.subscriptionData)) {
      return this.$swal({
        title: "Warning",
        icon: "warning",
        text: "Exiting without adding Payment Methods will disable the "+this.employerStore.fieldLabelSettings.groupLabel+" from enrolling "+this.employerStore.fieldLabelSettings.subscriberLabel,
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonColor: this.$vuetify.theme.themes.light.deny,
        denyButtonColor: this.$vuetify.theme.themes.light.success,
        confirmButtonText: "Continue without Payment Method",
        denyButtonText: "Add Payment",
      }).then((result) => {
        if (result.isConfirmed) { 
          // unload chargify before user leaves page
          next(); 
          this.chargify.unload();
        }
        else { return }
      })
    }else {
      next();
      this.chargify.unload();
    }
  }
}
</script>

<style scoped>
  .fs-16{
    font-size: 16px;
  }

  select::-ms-expand {
    display: none;
  }
  
  select {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    padding: 0.5em 2em 0.5em 0.5em;
    border: 1px solid #eee;
    font: inherit;
    line-height: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-image: linear-gradient(45deg, transparent 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, transparent 50%);
    background-position: right 15px top 1em, right 10px top 1em;
    background-size: 5px 5px, 5px 5px;
  }

</style>