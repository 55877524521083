import { domain as uat_domain, clientId as uat_clientId, audience as uat_audience } from "/auth_config.uat.json";
import { domain as dev_domain, clientId as dev_clientId, audience as dev_audience } from "/auth_config.json";
import { domain as prod_domain, clientId as prod_clientId, audience as prod_audience } from "/auth_config.production.json";

let domain, clientId, audience;

if(process.env.NODE_ENV === "development")
    {domain = dev_domain; clientId = dev_clientId; audience = dev_audience;}
if(process.env.NODE_ENV === "uat")
    {domain = uat_domain; clientId = uat_clientId; audience = uat_audience;}
if(process.env.NODE_ENV === "production")
    {domain = prod_domain; clientId = prod_clientId; audience = prod_audience;}

export { domain,  clientId,  audience };