import apiClient from "../services/api";
import { defineStore } from "pinia";

export const useChargifyStore = defineStore("chargify", {
  state: () => ({}),
  getters: {},
  actions: {
    async getEmployerSubscription(employerID){
      const data = await apiClient.get(`/chargify/GetSubscription?employerID=${employerID}`);
      return data.data;
    },
    async createEmployerSubscription(customerDetails, employerID, chargifyToken=null, chargifyTokenType=null){
      const data = await apiClient.post(`/chargify/CreateEmployerSubscription?employerID=${employerID}&chargifyToken=${chargifyToken}&chargifyTokenType=${chargifyTokenType}`, customerDetails);
      return data;
    },
    async updateEmployerSubscription(customerDetails = null, employerID, chargifyToken=null, chargifyTokenType=null){
      const data = await apiClient.put(`/chargify/UpdateEmployerSubscription?employerID=${employerID}&chargifyToken=${chargifyToken}&chargifyTokenType=${chargifyTokenType}`, customerDetails);
      return data.data;
    }
  },
});
