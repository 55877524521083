import Vue from "vue";
import VueRouter from "vue-router";
import HomeComponent from "../components/HomeComponent.vue";
import DashboardComponent from "../components/DashboardComponent.vue";
import UpdatePasswordComponent from "../components/UpdatePasswordComponent.vue";
import LoginView from "../views/LoginView.vue";

//member
import SubscriberAdminView from "../views/Auth/SubscriberAdminView.vue";
import NewMemberComponent from "../components/Member/NewMemberComponent.vue";
import EditMemberComponent from "../components/Member/EditMemberComponent.vue";
import MemberListingComponent from "../components/Member/MemberListingComponent.vue";
import ViewMemberComponent from "../components/Member/ViewMemberComponent.vue";

//member messaging
import MemberMessagingListingComponent from "../components/Member/MemberMessagingListingComponent.vue";

//user
import UsersView from "../views/Auth/UsersView.vue";
import UserListingComponent from "../components/User/UserListingComponent.vue";
import NewUserComponent from "../components/User/NewUserComponent.vue";
import EditUserComponent from "../components/User/EditUserComponent.vue";

//employer
import GroupAdminView from "../views/Auth/GroupAdminView.vue";
import EmployerListingComponent from "../components/Employer/EmployerListingComponent.vue";
import EditEmployerComponent from "../components/Employer/EditEmployerComponent.vue";
import NewEmployerComponent from "../components/Employer/NewEmployerComponent.vue";

//resources
import ResourcesList from "../components/Resources/ResourcesList.vue";


import ReportingView from "../views/Auth/ReportingView.vue";
import SettingsView from "../views/Auth/SettingsView.vue";
import ResourcesView from "../views/Auth/ResourcesView.vue";
import AuthViewVue from "../views/AuthView.vue";
import { authGuard, homeAuthGuard } from "../auth/authGuard";
import {roles} from "../data/definitions/roles";
import BillingComponent from "../components/BillingComponent.vue";
import PublicNewEmployerComponent from "../components/Employer/PublicNewEmployerComponent.vue";
import SelfSignUpComponent from "../components/Employer/SelfSignUpComponent.vue";
import { gncRoles } from "../data/definitions/gncRoles";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeComponent,
    beforeEnter: homeAuthGuard,
    meta: { notRequiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { notRequiresAuth: true }
  },
  {
    path: "/employerSignUp",
    name: "employerSignUp",
    component: SelfSignUpComponent,
    meta: { notRequiresAuth: true }
  },
  {
    path: "/completeEmployerSignUp",
    name: "completeEmployerSignUp",
    component: PublicNewEmployerComponent,
    meta: { notRequiresAuth: true }
  },
  {
    path: "/update-password",
    name: "update-password",
    component: UpdatePasswordComponent,
    meta: { notRequiresAuth: true }
  },
  {
    path: "/",
    name: "auth",
    component: AuthViewVue,
    beforeEnter: authGuard,
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: DashboardComponent,
        beforeEnter: authGuard
      },
      {
        path: "/billing",
        name: "billing",
        component: BillingComponent,
        beforeEnter: authGuard
      },
      {
        path: "group-admin",
        name: "group_admin",
        component: GroupAdminView,
        beforeEnter: authGuard,
        children: [
          {
            path: "",
            name: "group_admin_index",
            component: EmployerListingComponent
          },
          {
            path: "new",
            name: "group_admin_new",
            component: NewEmployerComponent
          },
          {
            path: "edit",
            name: "group_admin_edit",
            component: EditEmployerComponent
          },
        ]
      },
      {
        path: "subscriber-admin",
        component: SubscriberAdminView,
        beforeEnter: authGuard,
        children: [
          {
            path: "",
            name: "subscriber_admin_index",
            component: MemberListingComponent
          },
          {
            path: "new",
            name: "subscriber_admin_new",
            component: NewMemberComponent
          },
          {
            path: "edit",
            name: "subscriber_admin_edit",
            component: EditMemberComponent
          },
          {
            path: "view",
            name: "subscriber_admin_view",
            component: ViewMemberComponent
          }
        ]
      },
      {
        path: "messaging",
        name: "messaging",
        component: MemberMessagingListingComponent,
        meta: {
          roles: [roles.reviveAdmin]
        },
        beforeEnter: authGuard,
      },
      {
        path: "reporting",
        name: "reporting",
        component: ReportingView,
        beforeEnter: authGuard
      },
      {
        path: "users",
        name: "users",
        component: UsersView,
        meta: {
          roles: [roles.reviveAdmin]
        },
        beforeEnter: authGuard,
        children: [
          {
            path: "",
            name: "user_admin_index",
            component: UserListingComponent
          },
          {
            path: "new",
            name: "user_admin_new",
            component: NewUserComponent
          },
          {
            path: "edit",
            name: "user_admin_edit",
            component: EditUserComponent
          },
        ]
      },
      {
        path: "settings",
        name: "settings",
        component: SettingsView,
        meta: {
          roles: [roles.reviveAdmin]
        },
        beforeEnter: authGuard
      },
      {
        path: "resources",
        name: "resources",
        component: ResourcesView,
        
        children: [
          {
            path: "",
            name: "resources_list",
            component: ResourcesList,
            
          }
        ],
        beforeEnter: authGuard
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
});



router.beforeEach(async (to, from, next) => {
    let isAuthenticated = localStorage.getItem("isAuthenticated");
    let role = localStorage.getItem("roleName");

    if(role){
      if (role == gncRoles.memberSupport && isAuthenticated && to.meta.notRequiresAuth == null && !to.path.includes('subscriber')) {
        next({ name: 'subscriber_admin_index' });
      }else if (role == gncRoles.reporting && isAuthenticated && to.meta.notRequiresAuth == null && !to.path.includes('dashboard') && !to.path.includes('reporting') && !to.path.includes('resources')) {
        next({ name: 'dashboard' });
      }else if (role == gncRoles.generalUser && isAuthenticated && to.meta.notRequiresAuth == null && !to.path.includes('dashboard') && !to.path.includes('group') && !to.path.includes('subscriber') && !to.path.includes('reporting') && !to.path.includes('resources')) {
        next({ name: 'dashboard' });
      }else {
        next();
      }
    }else {
      next();
    }
})


export default router
