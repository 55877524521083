<template>
  <v-app :style="{background: $vuetify.theme.themes.light.background}"> 
    <router-view />
  </v-app>
</template>

<script>
import { useEmployerStore } from "./stores/employer";
import { useAuthStore } from "./stores/auth";
import { getInstance } from "./auth";

export default {
  name: 'App',
  setup() {
    const authStore = useAuthStore();
    const employerStore = useEmployerStore();

    return {
      authStore,
      employerStore,
    };
  },
  methods: {  
    assignColors(primaryColor, secondaryColor, backgroundColor) {
      this.$vuetify.theme.themes.light.primary = primaryColor ?? this.$vuetify.theme.themes.light.primary;
      this.$vuetify.theme.themes.light.secondary = secondaryColor ?? this.$vuetify.theme.themes.light.secondary;
      this.$vuetify.theme.themes.light.background = backgroundColor ?? this.$vuetify.theme.themes.light.background;
      this.storeToLocalStorage(
        this.$vuetify.theme.themes.light.primary,
        this.$vuetify.theme.themes.light.secondary,
        this.$vuetify.theme.themes.light.background
      )
    },
    storeToLocalStorage(primary, secondary, background){
      window.localStorage.setItem('primaryColor', primary);
      window.localStorage.setItem('secondaryColor', secondary);
      window.localStorage.setItem('backgroundColor', background);
    },
    clearLocalStorage(){
      window.localStorage.clear();
    },
    getColorsFromLocalStorage(){
      let priamryColor = window.localStorage.getItem('primaryColor');
      let secondaryColor = window.localStorage.getItem('secondaryColor');
      let backgroundColor = window.localStorage.getItem('backgroundColor');
      this.assignColors(priamryColor ?? null, secondaryColor ?? null, backgroundColor ?? null);
    }
  },
  created() {
    this.$watch("employerStore.customizationSettings.enableCustomization", (customizationEnabled) => {
      if(customizationEnabled){
        this.assignColors(
          this.employerStore.customizationSettings.primaryColor.hex,
          this.employerStore.customizationSettings.secondaryColor.hex,
          this.employerStore.customizationSettings.backgroundColor.hex
        );
      }else{
        window.localStorage.removeItem('primaryColor');
        window.localStorage.removeItem('secondaryColor');
        window.localStorage.removeItem('backgroundColor');
      }
    });

    this.$watch("employerStore.billingSettings.BillingInfoCompleted", (completedBillingInfo) => {
      const authService = getInstance();

      if(completedBillingInfo && !authService.forceResetpassword && !this.employerStore.isFromSelfService && (!this.employerStore.currentEmployerFull.documentURL && this.employerStore.billingSettings.billingMethod == 'individualBilling')) {
        return this.$swal({
          title: "Warning",
          icon: "warning",
          text: `You haven't uploaded a contract. Please upload a contract before you can add ${this.employerStore.fieldLabelSettings.secondarySubscriberLabel}.`,
          buttons: true,
          showDenyButton: true,
          confirmButtonColor: this.$vuetify.theme.themes.light.success,
          denyButtonColor: this.$vuetify.theme.themes.light.deny,
          confirmButtonText: "Yes",
          denyButtonText: "Cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "group_admin_edit", query: {employerID: this.employerStore.currentEmployerFull.employerID, scrollTo: 'contractUpload'} });
          }
        });
      }
    });

  },
  mounted() {
    this.getColorsFromLocalStorage();
  
  }
};
</script>
