import apiClient from "../services/api";
import { defineStore } from "pinia";

export const useResourceStore = defineStore("resource", {
  state: () => ({
      documentList: []
  }),
  getters: {

  },
  actions: {
    async getDocumentsByEmployerType(employerType, customerType) {
      try {
        await apiClient.get(`/document/getdocuments?employerType=${employerType}&customerType=${customerType}`).then((res) => {
          this.documentList = res.data;
        })
      }
      catch {
        this.documentList = [];
      }
    },
    async getDocumentForDownload(url) {
      try {
        var result = await apiClient.get("/document/DownloadDocument?doc="+url, { responseType: "arraybuffer" });
        return result.data;
      }
      catch {
        this.documentList = [];
      }
    },
  },
});
